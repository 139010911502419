.create-or-open {
  .c-contentWrapper {
    flex-direction: row !important;
  }
  .c-Button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:hover i {
      color: #fff;
    }
  }
}