//--- TODO: Make flex Mixins ---//
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Roboto:100,300,400,500,600,700,900|Fira+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap');
@import 'layout';
@import 'buttons';
@import 'forms';
@import 'table';
@import 'utils';

@font-face {
  font-family: SFMono;
  font-weight: 500;
  src: url('./fonts/SFMonoMedium.ttf') format("truetype");
}

@mixin unread {
  position: relative;

  &:after {
    content: ' ';
    width: 10px;
    height: 10px;
    background-color: #1890ff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 11px;
    top: calc(50% - 5px);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  font-weight: 400;
  color: #1f2f3d;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.loading-wrapper {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width: 250px;
    height: 250px;
    opacity: 0.1;
    filter: grayscale(100%);
    background: url(../imgs/logo.svg);
    background-size: 250px 250px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > button {
      padding: 10px 50px;
      background: linear-gradient(224.5deg, #ef4a32 0%, #ec4990 100%);
      outline: none;
      border: none;
      color: #fff;
      font-weight: 600;
      letter-spacing: 3px;
      text-transform: uppercase;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.table-column-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  z-index: 50;
  border-radius: 2px;
  color: #fff;
  padding: 10px;
  width: fit-content;
  height: fit-content;
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0.04;
    filter: grayscale(100%);
  }

  to {
    opacity: 1;
    filter: grayscale(0%);
  }
}

.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0.12;
  font-weight: bold;

  .four-0-four {
    display: flex;
    justify-content: space-evenly;
    font-size: 60px;
    text-align: center;
    line-height: 60px;
  }
  h1 {
    font-size: 50px;
    line-height: 60px;
    display: block;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }
}

//OVERRIDES
.ant-tooltip {
  &.side-item-tooltip {
    left: 52px !important;
  }
  &.side-item-tooltip,
  &.editmode-button-tooltip {
    max-width: 179px;
    user-select: none;
    height: 44px;
    overflow: hidden;
    padding-left: 4px;

    &:first-of-type {
      top: -44px;
    }

    &:before {
      position: absolute;
      top: 50%;
      margin-top: -4px;
      left: 0;
      height: 8px;
      width: 5px;
      background: url('../imgs/tooltip-bg.svg') left no-repeat;
      content: ' ';
    }

    .ant-tooltip-content {
      background: white;
      border: 1px solid #e1e6eb;
      border-radius: 3px;
      height: 44px;

      & > .ant-tooltip-arrow {
        display: none;
      }

      & > .ant-tooltip-inner {
        padding: 0;
        font-size: 13px;
        background: none;
        height: 42px;
        line-height: 42px;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Open Sans', sans-serif;

        span {
          margin: 0;
          padding: 0 15px 0 20px;
          margin-left: -5px;
          font-size: 13px;
          color: #4c4c4c;
          text-align: center;
          font-weight: normal;
          white-space: nowrap;
          display: inline-block;
          height: 42px;
          line-height: 42px;
          position: relative;
          z-index: 100;
        }
      }
    }
  }

  &.popup-dialog-tooltip {
    bottom: auto !important;

    .ant-tooltip-content {
      background: none;

      * {
        user-select: none;
      }

      & > .ant-tooltip-arrow {
        display: none;
      }

      & > .ant-tooltip-inner {
        box-shadow: none;
        padding: 0;
        background: none;
        font-size: 14px;
      }
    }
  }

  &.popup-dialog-tooltip {
    padding: 0;
    width: 100%;
    .ant-tooltip-content {
      padding: 0 0 0 8px;
      right: 90px !important;
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 #e7edf3;
      border: solid 1px #e1e6eb;
      margin-left: -9px;
    }

    i {
      color: #737d87;
      svg {
        width: 21px;
      }

      &.flux-icon-giveFeedback {
        svg {
          fill: none;
          stroke: #737d87;
        }
      }

      &.flux-icon-onboarding {
        svg {
          fill: #737d87;
          height: 24px;
        }
      }
    }

    .onboarding-video {
      border-bottom: solid 1px #e1e6eb;
      margin-left: -8px;
      width: calc(100% + 8px);
      padding: 11px 0;

      .video:hover {
        background-color: #f5f8fa;
        .video-name {
          color: #1890ff;
        }
        .video-duration {
          color: #525c67;
        }
      }
      .video {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        p {
          width: 145px;
          margin-bottom: 0;
          margin-right: 16px;
        }

        .video-preview {
          width: 65px;
          height: 40px;
          border-radius: 4px;
          background-color: #5f6d7b;
          margin-right: 16px;
        }

        .video-name {
          font-weight: 600;
          color: #5e646f;
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 4px;
        }

        .video-duration {
          color: #8d9aa8;
          font-size: 12px;
          line-height: 17px;
        }

        .flux-icon-close svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .ant-tooltip-inner {
      padding: 0 !important;
    }

    .popup-dialog-menu {
      padding: 14px 0;
      button,
      a {
        border: none;
        margin-left: -8px;
        width: calc(100% + 8px);
        padding: 0 22px;
        display: flex;
        align-items: center;
        color: #5e646f;
        line-height: 40px;
        cursor: pointer;
        outline: none;

        &.unread {
          @include unread;
        }

        i {
          text-align: center;
          width: 20px;
          margin-right: 13px;
        }

        .flux-icon-userSettings svg {
          width: 15px;
          height: 17px;
        }
        .flux-icon-billingSub svg {
          width: 20px;
          height: 19px;
        }
        .flux-icon-userManagement svg {
          width: 18.6px;
          height: 24px;
        }
        .flux-icon-logout svg {
          width: 20px;
          height: 22px;
        }
        .flux-icon-productOverview svg {
          width: 20px;
          height: 21px;
        }
        .flux-icon-productPortal svg {
          width: 20px;
          height: 20px;
        }
        .flux-icon-knowledgeBase svg {
          width: 20px;
          height: 21px;
        }
        .flux-icon-contactSupport svg {
          width: 20px;
          height: 21px;
        }
        .flux-icon-productChangelog svg {
          width: 20px;
          height: 20px;
        }
      }
      button {
        background-color: transparent;
      }
      button:hover,
      a:hover {
        background-color: #f5f8fa;
        color: #ef4a32;
        //font-weight: 600;

        .flux-icon-giveFeedback svg {
          stroke: #ef4a32;
        }

        i {
          color: #ef4a32;
        }
      }
      .product-changelog-container {
        position: absolute;
        left: -213px;
        padding-right: 6px;
        top: 0;

        .product-changelog {
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 #e7edf3;
          padding-left: 8px;
          width: 200px;
          background: #ffffff;

          .unread {
            @include unread;
          }
        }
      }
    }
  }
}

.ant-message {
  z-index: 3000;
  &-notice {
    &-content {
      max-width: 50%;
    }
  }
}

* {
  scrollbar-color: #229ee6 #eff4f8;
  scrollbar-width: thin !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  border-radius: 3.5px;
  background-color: #eff4f8;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  width: 4px;
  height: 4px;
  border-radius: 3.5px;
  background-color: #eff4f8;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 3.5px;
  background-color: #229ee6;
}

.ag-body-horizontal-scroll-viewport {
  min-width: 100% !important;
}

// .ag-cell-range-selected-1:not(.ag-cell-inline-editing),
.ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: transparent !important;
}

.ant-modal,
.dynamic-sidebar-body,
.node-settings {
  .ant-select-multiple {
    position: relative;
    height: 35px;
    padding: 0;
    margin: 0;

    .ant-select-selection__rendered {
      top: 0;
      position: absolute;
      width: 100%;
      height: 33px;
      margin: 0;

      &:after {
        top: 0;
        position: absolute;
      }

      .ant-select-selection__placeholder {
        position: absolute;
        top: 50%;
        margin-top: -17px;
        height: 33px;
        line-height: 33px;
        padding-left: 6px;
      }

      ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 33px;
        overflow: hidden;
        padding: 5px 20px 5px 6px !important;

        li {
          height: 24px;
          margin: 0 !important;

          input:not([type='checkbox']) {
            height: 24px;
          }
        }
      }
    }
  }
}

@mixin titleLink {
  a {
    display: inline-block;
    margin-left: 20px;
    padding-left: 20px;
    border-left: solid 1px #e9e9e9;
    line-height: 24px;
  }
  svg {
    margin-right: 9px;
    fill: #1890ff;
  }
}

.ant-modal {
  max-height: calc(100vh - 10px);
  padding: 0 !important;
  transform-origin: 50% 50% !important;

  &-content {
    max-height: 100%;
    border-radius: 10px;
    box-shadow: 0 0px 15px 2px rgba(0,0,0,0.2);
    .with-common-text-margin p {
      margin-bottom: 10px;
    }
  }

  &-body {
    max-height: calc(100vh - 75px);
    height: auto;

    form {
      border-radius: 0 0 4px 4px;
      overflow: auto;
      max-height: calc(100vh - 80px);
      height: auto;
    }
  }

  &-header {
    padding: 18px 20px 14px 30px;
    border-bottom: 1px solid #e9e9e9;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #404d66;
    border-radius: 10px 10px 0px 0px;
  }
  &-title {
    i.flux-icon-close {
      &:hover {
        color: rgba(126, 142, 173, 0.8) !important;
      }
    }

    .title-link {
      @include titleLink;
    }
  }
  &-body {
    padding: 0;
  }
  &-footer {
    text-align: left;
    border-top: none;
  }
}

.flux-buttons--filter {
  .traffic-source-select {
    .ant-select-selection {
      max-height: 35px;
      overflow: hidden;
    }
  }
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 13px;
}

.loading-indicator {
  position: relative;
  width: 35px;
  height: 35px;
  border: solid 5px transparent;
  display: flex;
  justify-content: center;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid #1890ff;
    border-radius: 50%;
    animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1890ff transparent transparent transparent;
    z-index: 1;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }

    &:nth-child(4) {
      border: 4px solid #f1f4fa;
      z-index: 0;
    }
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[aria-disabled='true'] {
  cursor: not-allowed !important;
  * {
    cursor: not-allowed !important;
    color: gray;
  }
}

.ant-select-multiple .ant-select-search--inline {
  position: absolute;
}
.ant-select-open {
  .ant-select-multiple .ant-select-search--inline {
    position: static;
  }
}

._hidden {
  display: none !important;
}
