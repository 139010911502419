.flux-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  font-family: 'Open Sans', sans-serif;
  &.content-start {
    justify-content: start;
  }
  &.align-center {
    align-items: center;
    align-self: center;

    .ff-buttons {
      align-self: center;
    }
  }
  &.flux-buttons-conditions{
    height: 63px;
  }
  & > div {
    display: flex;
    align-items: center;
  }
  &--crud {
    @media screen and (max-width: 1280px) {
      div {
        .ff-button-switch {
          margin-right: 9px;
        }
        &:first-child {
          margin-right: 8px;
        }
         &:last-child {
          margin-left: 8px;
         }
      }
    }
    .name {
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #404d66;
    }
    & > i {
      font-size: 24px;
      margin-right: 20px;
      color: #1890ff;
      cursor: pointer;
    }
    .edit, .modal-trigger, .sidebar-trigger {
      display: flex;
      align-items: center;
      font-size: 14px;
      //color: #89959c;
      color: #4b606d;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      button {
        margin-right: 9px;
      }
    }

    .modal-trigger, .sidebar-trigger {
      cursor: pointer;
      margin-right: 15px;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      color: #1890ff;
      position: relative;

      .visible {
        position: absolute;
        left: 33px;
      }

      .hidden {
        visibility: hidden;
      }

      i {
        margin-right: 9px;
      }
    }
  }
  &--filter {
    > * {
      margin-left: 20px;
      @media screen and (max-width: 1280px) {
        margin-left: 8px;
      }
    }
    .filter-counter {
      margin-left: 10px;
      color: #1890ff;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
    }
    .switch-wrapper {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #89959c;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      .flux-icon-information {
        margin-left: 10px;
      }

      button {
        margin-right: 9px;
      }
    }
  }
}
