@import 'vars/layout';

.flux-layout {
  height: auto;
  min-height: $layout-height;
  background: #fff;

  &.subscriptionCancelled {
    min-height: $layout-height-with-ended-subscription;
  }

  .ant-layout-sider {
    height: auto;
    min-height: 100%;
    z-index: 1;
  }

  .ant-layout-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: rgb(247 247 249);
    overflow-y: hidden;

    .container {
      padding: 0 20px;
      height: 100%;
      overflow: hidden;
    }

    .overlay-wrapper {
      box-shadow: none !important;
      height: calc(100% - 50px);
      width: auto;
      left: 60px;
      top: 5px;

      .flux-buttons--filter {
        margin-right: 30px;
      }

      .overlay-close-icon {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 28px;
        height: 28px;

        i,
        svg {
          width: 100%;
          height: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .ag-theme-balham {
      .partial-height {
        position: relative;

        .ag-floating-bottom {
          height: 39px;
          width: 100%;
					border-radius: 0px 0px 5px 5px;

          .ag-floating-bottom-container {
            .ag-row {
              font-weight: bold;
              background-color: #f5f8fa;
              height: 36px !important;
							border-radius: 0px 0px 5px 5px;
            }

            [col-id='ag-Grid-AutoColumn'] {
              border-right: 0;
            }

            *:before {
              display: none;
            }
          }
        }
      }
    }

    .autoHeight {
      &-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &-table-container {
        height: 100%;
        min-height: 240px;
        position: relative;

        .ag-theme-balham {
          height: 100%;
          min-height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          width: calc(100% - 40px);

          .ag-theme-balham {
            display: block;
            position: initial;
            min-height: 1px;
            height: 1px;
          }
        }
      }
    }
  }
}

.ant-modal {
  .flux-loading {
    background: rgba(255,255,255, 0.5) !important;
    margin-top: 57px;
    height: calc(100% - 57px) !important;
  }
}
