$googleBlue: #4285f4;
$ffRed: #da365b;

.App {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;

  .ant-layout.ant-layout-has-sider {
    max-height: 100%;
  }
  .ant-layout.ant-layout-has-sider > .ant-layout {
    overflow-x: inherit;
    z-index: 1;
  }

  .flux-layout .ant-layout-content {
    overflow-y: auto;
    overflow-x: auto;
  }
}

.gist-messenger-iframe {
  box-shadow: 0px 5px 40px rgba(0,0,0,0.3) !important;
	@media only screen and (min-width: 800px) {
    min-height: 400px;
  	width: 450px!important;
	}
  @media only screen and (min-width: 1200px) {
		width: 500px!important;
    min-height: 500px;
	}
	@media only screen and (min-width: 1600px) {
		width: 500px!important;
    min-height: 600px;
	}
	@media only screen and (min-width: 1800px) {
		width: 600px!important;
    min-height: 750px;
	}
  @media only screen and (min-width: 2300px) {
		width: 600px!important;
    min-height: 1000px;
	}
}

.gist-article-sidebar-iframe > iframe{
	box-shadow: 0px 5px 40px rgba(0,0,0,0.3) !important;
	@media only screen and (max-width: 1599px) {
  	width: 100%!important;
    max-width: 700px;
	}
	@media only screen and (min-width: 1600px) {
		width: 850px!important;
	}
	@media only screen and (min-width: 1921px) {
		width: 950px!important;
	}
}

.card {
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
  &.no-padding {
    padding: 0;
  }
  &.no-border {
    border: none;
  }
  &.is-shadow {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.send-traffic {
  position: absolute;
  z-index: 99;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  left: 310px;
  top: 10px;
  transition: all 0.2s ease-in-out;
}

.send-traffic:hover {
  transform: scale(1.1);
}

.send-traffic > a {
  display: block;
  padding: 10px;
  text-decoration: none !important;
}

.send-traffic > a:visited {
  text-decoration: none;
}

.ant-scrolling-effect {
  width: 100% !important;
}

#canny-changelog-iframe {
  left: 95px !important;
  bottom: 70px !important;
  right: unset !important;
  top: unset !important;
}

.adsHeaderBorderClass {
  border-bottom: 3px solid $googleBlue;
}

.funnelfluxHeaderBorderClass {
  border-bottom: 3px solid $ffRed;
}