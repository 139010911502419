@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Roboto:100,300,400,500,600,700,900|Fira+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap);
.flux-icon {
  color: rgba(126, 142, 173, 0.5);
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  cursor: pointer;
}
.flux-icon > * {
  line-height: 1;
}
.flux-icon-no-icon {
  height: 35px;
  line-height: 33px;
  width: 40px;
  color: #fff;
  background: #1890ff;
  align-self: flex-end;
  border-radius: 4px;
  text-align: center;
}
.flux-icon-addnew {
  color: #1890ff;
}
.flux-icon-addnewtab {
  fill: #1890ff;
}
.flux-icon-addnewtab:hover {
  fill: #3c42ff;
}
.flux-icon-copy {
  color: #1890ff;
  height: 35px;
  width: 40px;
}
.flux-icon-copy:active svg rect,
.flux-icon-copy:active svg path {
  fill: #1280ce;
}
.flux-icon-copy.disabled svg rect,
.flux-icon-copy.disabled svg path {
  fill: #bfc9d4;
}
.flux-icon-information {
  color: #1890ff;
  font-size: 16px;
  display: inline-flex !important;
}
.flux-icon-tableDelete svg {
  height: 14px;
  width: 14px;
}
.flux-icon-tableCopy svg {
  height: 18px;
  width: 19px;
}
.flux-icon-funnelGroupsCreate, .flux-icon-funnelCreate {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #1890ff;
}
.flux-icon-flowCreate {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #1890ff;
}
.flux-icon-flowPath {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.flux-icon-danger {
  width: 18px;
  height: 16px;
  font-size: 18px;
  color: #e9455e;
}
.flux-icon-horizontalArrow {
  width: 12px;
  height: 8px;
  font-size: 12px;
}
.flux-icon-collapseArrow {
  width: 9px;
  height: 6px;
  font-size: 12px;
}
.flux-icon-close:hover {
  color: #e9455e;
}
.flux-icon-modify:hover svg, .flux-icon-swap:hover svg {
  fill: #1890ff;
}
.flux-icon-delete:hover svg {
  fill: #e9455e;
}
.flux-icon-zoomIn, .flux-icon-zoomOut {
  width: 10px;
  height: 2px;
}
.flux-icon-zoomIn:hover, .flux-icon-zoomOut:hover {
  color: #8e9dad;
}
.flux-icon-outlinedCondition path {
  fill: none;
  stroke: currentColor;
}
.flux-icon-rippleLoading {
  margin: auto;
  display: block;
}
.flux-icon-allActionsLinker {
  width: 100px;
}
.flux-icon-allActionsLinker .cls-1 {
  fill: none;
  stroke: #bfcad5;
  stroke-miterlimit: 10;
}
.flux-icon-allActionsLinker .cls-2 {
  fill: #fff;
}
.flux-icon-allActionsLinker .cls-3 {
  fill: #e1e6eb;
}
.flux-icon-allActionsLinker .cls-4 {
  fill: #81878e;
}
.flux-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: #5d6d88;
}
.ant-message .anticon-close-circle {
  margin-left: 10px;
}
.c-loading__tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: 130px;
  height: 45px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 20;
  visibility: visible;
  opacity: 1;
}
.c-loading__tooltip--hidden {
  visibility: hidden;
  opacity: 0;
}
.c-ffIcon__xCircle:hover {
  color: #e9455e;
}
.ant-btn.c-ffButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: none;
  position: relative;
}
.ant-btn.c-ffButton .c-ffButton__loadingIndicator {
  display: flex;
  grid-gap: 3px;
  gap: 3px;
  position: absolute;
  margin-top: -3px;
}
.ant-btn.c-ffButton .c-ffButton__loadingDot {
  color: #1890ff;
  font-size: 22px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: buttonLoadingAnimation 1.8s infinite ease-in-out;
  animation: buttonLoadingAnimation 1.8s infinite ease-in-out;
}
.ant-btn.c-ffButton .c-ffButton__loadingDot:nth-child(1) {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
}
.ant-btn.c-ffButton .c-ffButton__loadingDot:nth-child(2) {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
}
.ant-btn.c-ffButton .c-ffButton__skeletonLoader {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
}
.ant-btn.c-ffButton--primary {
  background-color: #1890ff;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--defaultColor {
  background-color: #1890ff;
  color: #fff;
  border: 2px solid #1890ff;
}
.ant-btn.c-ffButton--primary.c-ffButton--defaultColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--defaultColor:active {
  background-color: #6da7ff;
}
.ant-btn.c-ffButton--primary.c-ffButton--dangerColor {
  background-color: #e9455e;
  border: 2px solid #e9455e;
}
.ant-btn.c-ffButton--primary.c-ffButton--dangerColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--dangerColor:active, .ant-btn.c-ffButton--primary.c-ffButton--dangerColor:focus {
  background-color: #ee5a74;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--dangerColor .c-ffButton__skeletonLoader {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 70%), transparent;
  background-repeat: repeat-y;
  background-size: 200px 500px;
  background-position: -200px 0;
  -webkit-animation: shine 1s infinite;
          animation: shine 1s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.ant-btn.c-ffButton--primary.c-ffButton--warningColor {
  background-color: #ff8e23;
  border: 2px solid #ff8e23;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--warningColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--warningColor:active, .ant-btn.c-ffButton--primary.c-ffButton--warningColor:focus {
  background-color: #ffa550;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor {
  background-color: #e7edf2;
  border: 2px solid #e7edf2;
  color: #515151;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor .c-ffButton__icon {
  color: #515151;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--grayColor:active, .ant-btn.c-ffButton--primary.c-ffButton--grayColor:focus {
  background-color: #c4c6c8;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor:hover .c-ffButton__icon, .ant-btn.c-ffButton--primary.c-ffButton--grayColor:active .c-ffButton__icon, .ant-btn.c-ffButton--primary.c-ffButton--grayColor:focus .c-ffButton__icon {
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--disabled, .ant-btn.c-ffButton--primary.c-ffButton--disabled:hover {
  background-color: #bfc9d4;
  border: 2px solid #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--primary .c-ffButton__icon {
  color: #fff;
}
.ant-btn.c-ffButton--primary .c-ffButton__loadingDot {
  color: #fff;
}
.ant-btn.c-ffButton--outlined {
  background-color: transparent;
  border: 2px solid #1890ff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--defaultColor {
  color: #1890ff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--defaultColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--defaultColor:active {
  background-color: #6da7ff;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--dangerColor {
  border-color: #e9455e;
  color: #e9455e;
}
.ant-btn.c-ffButton--outlined.c-ffButton--dangerColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--dangerColor:focus {
  background-color: #e9455e;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--warningColor {
  border-color: #ff8e23;
  color: #ff8e23;
}
.ant-btn.c-ffButton--outlined.c-ffButton--warningColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--warningColor:focus {
  background-color: #ff8e23;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--grayColor {
  border-color: #505f70;
  color: #505f70;
}
.ant-btn.c-ffButton--outlined.c-ffButton--grayColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--grayColor:focus {
  background-color: #505f70;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--grayColor .c-ffButton__icon {
  color: #505f70;
  fill: #505f70;
}
.ant-btn.c-ffButton--outlined.c-ffButton--disabled {
  background-color: #bfc9d4;
  border-color: #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--disabled:hover, .ant-btn.c-ffButton--outlined.c-ffButton--disabled:focus {
  background-color: #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--loading:hover {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--loading:hover .c-ffButton__loadingDot {
  color: #fff;
}
.ant-btn.c-ffButton--outlined .c-ffButton__icon {
  color: #1890ff;
}
.ant-btn.c-ffButton--outlined .c-ffButton__loadingDot {
  color: #1890ff;
}
.ant-btn.c-ffButton--help {
  font-size: 13px;
  width: -webkit-max-content;
  width: max-content;
}
.ant-btn.c-ffButton--help.c-ffButton--defaultColor {
  background-color: #1890ff;
  color: #fff;
  border: none;
}
.ant-btn.c-ffButton--help.c-ffButton--disabled, .ant-btn.c-ffButton--help.c-ffButton--disabled:hover {
  background-color: #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--help.c-ffButton:hover, .ant-btn.c-ffButton--help.c-ffButton:active {
  background-color: #6da7ff;
}
.ant-btn.c-ffButton--help .c-ffButton__icon {
  color: #fff;
}
.ant-btn.c-ffButton--ghost {
  background-color: transparent;
  border: none;
  padding: 0px;
  box-shadow: none;
}
.ant-btn.c-ffButton--ghost.c-ffButton--defaultColor {
  border-color: transparent;
}
.ant-btn.c-ffButton--ghost.c-ffButton--defaultColor .c-ffButton__icon {
  color: #1890ff;
}
.ant-btn.c-ffButton--ghost.c-ffButton--disabled {
  background-color: transparent;
  cursor: not-allowed;
  pointer-events: none;
  border: none;
}
.ant-btn.c-ffButton--ghost.c-ffButton--disabled .c-ffButton__icon {
  color: #bfc9d4;
}
.ant-btn.c-ffButton--ghost.c-ffButton--loading:hover {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn.c-ffButton--ghost.c-ffButton--loading:hover .c-ffButton__loadingDot {
  color: #fff;
}
.ant-btn.c-ffButton--withIcon {
  grid-gap: 10px;
  gap: 10px;
}
.ant-btn.c-ffButton--sm {
  height: 26px;
}
.ant-btn.c-ffButton--md {
  height: 35px;
}
.ant-btn.c-ffButton--lg {
  height: 40px;
}
.ant-btn.c-ffButton--loading .c-ffButton__icon, .ant-btn.c-ffButton--loading .c-ffButton__text {
  visibility: hidden;
}
.ant-btn.c-ffButton--loadingSkeleton .c-ffButton__icon, .ant-btn.c-ffButton--loadingSkeleton .c-ffButton__text {
  visibility: visible;
}

@-webkit-keyframes buttonLoadingAnimation {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes buttonLoadingAnimation {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}
@keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}
.c-ffSelect--error .ant-select-selector {
  border-color: #f5222d !important;
}

.ant-select-dropdown {
  max-width: 400px;
}
.ant-select-dropdown .c-ffSelect__createNewView .ant-select-item-option-content {
  color: #1890ff;
}
.ant-select-dropdown .c-ffSelect__selectAll, .ant-select-dropdown .c-ffSelect__clearAll {
  border-bottom: 1px solid #e1e6eb;
}

.c-ffSelect__select {
  height: 35px;
}
.c-ffSelect--sizeMiddle .ant-select-selection-placeholder {
  line-height: 35px !important;
}
.c-ffSwitch {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.c-ffSwitch--disabledCompletely ~ .c-ffSwitch__label {
  color: #ccc;
}
.c-ffSwitch--redOnOff.c-ffSwitch--disabled {
  background-color: red;
}
.c-ffSwitch__button {
  width: auto;
}
.c-tooltip__icon {
  margin-left: 5px;
}
.c-tooltip__switchButton .ant-tooltip-content {
  width: 470px;
}
.c-tooltip__funnelDefaultCost .ant-tooltip-content {
  width: 460px;
}
.c-tooltip__funnelDefaultCost .ant-tooltip-content ul {
  margin-left: 25px;
}
.c-tooltip__mapIncomingAction {
  width: 400px !important;
  max-width: 400px !important;
}
.c-tooltip__mapIncomingAction p:last-child {
  margin-bottom: 0;
}
.c-tooltip .ant-tooltip-inner {
  color: #465565 !important;
  background-color: #ffffff !important;
  padding: 20px 25px 10px 25px;
  max-width: 100%;
}
.c-tooltip .ant-tooltip-arrow {
  display: none;
}
.c-tooltip__title {
  font-weight: bold;
  margin-bottom: 15px;
}
.ant-picker.c-ffDatepicker {
  max-width: 303px;
  border-radius: 4px;
  width: 100%;
}
.ant-picker.c-ffDatepicker .ant-calendar-picker-input {
  border-color: #d9d9d9;
}
.ant-picker.c-ffDatepicker .ant-calendar-picker-input:hover {
  border-color: #40a9ff;
}
.ant-picker.c-ffDatepicker .ant-calendar-range-picker-input {
  font-size: 14px;
}
.ant-picker.c-ffDatepicker .ant-picker-focused,
.ant-picker.c-ffDatepicker .ant-picker:hover {
  border-color: #40a9ff;
}
.ant-picker.c-ffDatepicker__size--middle {
  height: 35px;
}
.ant-picker.c-ffDatepicker__size--middle .ant-calendar-picker-input {
  height: 35px;
}

.c-ffDatepicker__dropdown .ant-calendar-footer-btn .ant-calendar-range-quick-selector .ant-tag {
  margin-right: 3px;
  cursor: pointer;
}
.c-ffDatepicker__dropdown .ant-picker-panels,
.c-ffDatepicker__dropdown .ant-picker-panel,
.c-ffDatepicker__dropdown .ant-picker-datetime-panel,
.c-ffDatepicker__dropdown .ant-picker-time-panel {
  width: 100%;
}
.c-ffDatepicker__footer {
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
}
.c-ffDatepicker__footerRow {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.c-ffDatepicker__footerRowTitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
}
.c-ffDatepicker__footerRowBtn {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer;
  height: 24px;
  font-size: 12px;
  line-height: initial;
  border: 1px solid #91d5ff;
  padding: 0px 5px;
  border-radius: 4px;
  width: -webkit-max-content;
  width: max-content;
}
.c-ffDatepicker__timeZoneSelector .ant-select-selector {
  padding-top: 1px !important;
}
.ant-input .c-ffInput,
.c-ffInput {
  height: 35px;
  border-radius: 4px;
}
.ant-input .c-ffInput.has-error,
.c-ffInput.has-error {
  border-color: #f5222d !important;
}
.ant-input .c-ffInput:hover,
.c-ffInput:hover {
  border-color: #40a9ff;
}
.ant-input .c-ffInput--sm,
.c-ffInput--sm {
  height: 25px;
}
.ant-input .c-ffInput input::-webkit-outer-spin-button,
.ant-input .c-ffInput input::-webkit-inner-spin-button,
.c-ffInput input::-webkit-outer-spin-button,
.c-ffInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-input .c-ffInput input[type=number],
.c-ffInput input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}
.ant-input.c-ffTextarea {
  min-height: 35px !important;
  padding-top: 7px;
}
.ant-input.c-ffTextarea--notResizable {
  resize: none;
  overflow-y: auto;
}
.c-ffTable {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 14px;
  font-family: sans-serif;
  min-width: 400px;
  border: 2px solid #e1e6eb;
}
.c-ffTable__headCell {
  padding: 12px 15px;
  color: #46535e;
}
.c-ffTable__headCell:not(:last-child) {
  border-right: 1px solid #e1e6eb;
}
.c-ffTable__rowCell {
  padding: 6px 15px;
  font-weight: 500;
  font-size: 14px;
  color: #666d7b;
}
.c-ffTable__rowCell:not(:last-child) {
  border-right: 1px solid #e1e6eb;
}
.c-ffTable__theadRow {
  background-color: #f5f8fa;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}
.c-ffTable__tbodyRow {
  border-bottom: 1px solid #dddddd;
}
.c-ffTable__loadingIcon svg {
  fill: #1890ff;
}
.c-ffIconTooltip__overlay .ant-tooltip-inner {
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  color: #465565;
}
.c-ffIconTooltip__overlay .ant-tooltip-arrow {
  display: none;
}
.c-ffAddGroup {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.c-ffAddGroup__addIcon {
  cursor: pointer;
  font-size: 24px;
  color: #1890ff !important;
}
.c-ffAddGroup__col {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.c-ffAddGroup__col--minimumWidth {
  flex: unset;
  width: 50px;
}
.c-ffAddGroup__row {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}
.c-ffAddGroup__addDivider {
  width: 100%;
  min-width: 100px !important;
  max-width: 300px;
  margin: 5px auto 0px !important;
}
.c-ffAddGroup__addDivider::before, .c-ffAddGroup__addDivider::after {
  top: 0px !important;
}
.c-ffBlock {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e1e6eb;
}
.c-row {
  display: flex;
  flex-direction: row;
}
.c-col {
  display: flex;
  flex-direction: column;
}
.c-ffIconedButton__refresh {
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  padding: 4px;
  cursor: pointer;
  transition: 0.3s ease;
}
.c-ffIconedButton__refresh:hover {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: #1890ff;
}
.c-ffIconedButton__refreshButton {
  height: 40px;
  width: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: 10px;
  color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-ffIconedButton__refreshButton:hover {
  background-color: #ffffff;
  color: unset;
}
.c-ffIconedButton__refreshButton--dashboard {
  height: 40px;
  width: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: 10px;
  color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-ffIconedButton__refreshButton--dashboard:hover {
  background-color: #eff5fe;
  color: unset;
}
.c-ffIconedButton__refreshIcon {
  display: contents !important;
}
.c-ffIconedButton__onboarding {
  padding-left: 5px !important;
  padding-right: 12px !important;
}
.c-ffIconedButton__delete {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(126, 142, 173, 0.5019607843);
  border: 1.5px solid rgba(126, 142, 173, 0.5019607843);
  width: 28px;
  height: 28px;
  border-radius: 28px;
  padding: 4px;
  cursor: pointer;
}
.c-ffIconedButton__delete:hover {
  color: #e9455e;
  border-color: #e9455e;
}
.c-ffIconedButton__edit {
  height: 35px;
  width: 33px;
  border-radius: 6px;
  padding: 6px;
  background-color: #1890ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.c-ffIconedButton__edit--disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #bfc9d4;
}
.c-ffIconedButton__filter {
  height: 35px;
  width: 33px;
  border-radius: 6px;
  padding: 7px;
  background-color: #00b7aa;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.c-ffIconedButton__filter:hover {
  background-color: #ee5a74;
}
.c-ffIconedButton__filter--disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #bfc9d4;
}
.c-ffIconedButton__trash {
  height: 35px;
  width: 33px;
  border-radius: 6px;
  padding: 9px;
  background-color: #e9455e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.c-ffIconedButton__trash:hover {
  background-color: #ee5a74;
}
.c-ffIconedButton__trash--disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #bfc9d4;
}
.c-ffModal .ant-modal-footer {
  padding: 0;
}
.c-ffModal .ant-modal-close {
  display: none;
}
.c-ffModal .ant-modal-mask {
  display: none;
}
.c-ffModal__closeIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  color: rgba(126, 142, 173, 0.5019607843);
  justify-content: center;
  align-items: center;
}
.c-ffModal__closeIcon:hover {
  color: rgba(126, 142, 173, 0.8);
}
.c-ffModal__title {
  font-weight: 600;
  font-size: 16px;
  color: #5d6d88;
}
.ant-btn.c-ffButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: none;
  position: relative;
}
.ant-btn.c-ffButton--minimum {
  width: -webkit-min-content;
  width: min-content;
}
.ant-btn.c-ffButton--block {
  width: 100%;
}
.ant-btn.c-ffButton--minimum {
  width: -webkit-min-content;
  width: min-content;
}
.ant-btn.c-ffButton--block {
  width: 100%;
}
.ant-btn.c-ffButton .c-ffButton__loadingIndicator {
  display: flex;
  grid-gap: 3px;
  gap: 3px;
  position: absolute;
  margin-top: -3px;
}
.ant-btn.c-ffButton .c-ffButton__loadingDot {
  color: #1890ff;
  font-size: 22px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: buttonLoadingAnimation 1.8s infinite ease-in-out;
  animation: buttonLoadingAnimation 1.8s infinite ease-in-out;
}
.ant-btn.c-ffButton .c-ffButton__loadingDot:nth-child(1) {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
}
.ant-btn.c-ffButton .c-ffButton__loadingDot:nth-child(2) {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
}
.ant-btn.c-ffButton .c-ffButton__skeletonLoader {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
}
.ant-btn.c-ffButton--primary {
  background-color: #4e18ff;
  color: #fff;
  border: none;
}
.ant-btn.c-ffButton--primary.c-ffButton--defaultColor {
  background-color: #1890ff;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--defaultColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--defaultColor:active {
  background-color: #6da7ff;
}
.ant-btn.c-ffButton--primary.c-ffButton--dangerColor {
  background-color: #e9455e;
}
.ant-btn.c-ffButton--primary.c-ffButton--dangerColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--dangerColor:active, .ant-btn.c-ffButton--primary.c-ffButton--dangerColor:focus {
  background-color: #ee5a74;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--dangerColor .c-ffButton__skeletonLoader {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 70%), transparent;
  background-repeat: repeat-y;
  background-size: 200px 500px;
  background-position: -200px 0;
  -webkit-animation: shine 1s infinite;
          animation: shine 1s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.ant-btn.c-ffButton--primary.c-ffButton--warningColor {
  background-color: #ff8e23;
}
.ant-btn.c-ffButton--primary.c-ffButton--warningColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--warningColor:active, .ant-btn.c-ffButton--primary.c-ffButton--warningColor:focus {
  background-color: #ffa550;
}
.ant-btn.c-ffButton--primary.c-ffButton--onboardingColor {
  background-color: #5e2ff8;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--onboardingColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--onboardingColor:active, .ant-btn.c-ffButton--primary.c-ffButton--onboardingColor:focus {
  background-color: #9d2eff;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor {
  background-color: #939393;
  border: 2px solid #939393;
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor .c-ffButton__icon {
  color: #fff;
}
.ant-btn.c-ffButton--primary.c-ffButton--grayColor:hover, .ant-btn.c-ffButton--primary.c-ffButton--grayColor:active, .ant-btn.c-ffButton--primary.c-ffButton--grayColor:focus {
  background-color: #7a7a7a;
}
.ant-btn.c-ffButton--primary.c-ffButton--disabled, .ant-btn.c-ffButton--primary.c-ffButton--disabled:hover {
  background-color: #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--primary .c-ffButton__icon {
  color: #fff;
}
.ant-btn.c-ffButton--primary .c-ffButton__loadingDot {
  color: #fff;
}
.ant-btn.c-ffButton--outlined {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
}
.ant-btn.c-ffButton--outlined.c-ffButton--defaultColor {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--defaultColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--defaultColor:active {
  border-color: #1890ff;
  background-color: #6da7ff;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--dangerColor {
  border-color: #e9455e;
  color: #e9455e;
}
.ant-btn.c-ffButton--outlined.c-ffButton--dangerColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--dangerColor:focus {
  background-color: #e9455e;
}
.ant-btn.c-ffButton--outlined.c-ffButton--warningColor {
  border-color: #ff8e23;
  color: #ff8e23;
}
.ant-btn.c-ffButton--outlined.c-ffButton--warningColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--warningColor:focus {
  background-color: #ffa550;
}
.ant-btn.c-ffButton--outlined.c-ffButton--onboardingColor {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--onboardingColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--onboardingColor:active {
  border-color: #1890ff;
  background-color: #6da7ff;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--grayColor {
  background-color: #fff;
  color: #505f70;
  border-width: 1px;
}
.ant-btn.c-ffButton--outlined.c-ffButton--grayColor:hover, .ant-btn.c-ffButton--outlined.c-ffButton--grayColor:focus {
  background-color: #fff;
  border-color: #6a757f;
  color: #505f70;
}
.ant-btn.c-ffButton--outlined.c-ffButton--grayColor .c-ffButton__icon {
  color: #505f70;
  fill: #505f70;
}
.ant-btn.c-ffButton--outlined.c-ffButton--disabled {
  background-color: #bfc9d4;
  border-color: #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--disabled:hover, .ant-btn.c-ffButton--outlined.c-ffButton--disabled:focus {
  background-color: #bfc9d4;
  border-color: #bfc9d4;
}
.ant-btn.c-ffButton--outlined.c-ffButton--loading:hover {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn.c-ffButton--outlined.c-ffButton--loading:hover .c-ffButton__loadingDot {
  color: #fff;
}
.ant-btn.c-ffButton--outlined .c-ffButton__icon {
  color: #1890ff;
}
.ant-btn.c-ffButton--outlined .c-ffButton__loadingDot {
  color: #1890ff;
}
.ant-btn.c-ffButton--help {
  font-weight: 500;
  font-size: 13px;
  width: -webkit-max-content;
  width: max-content;
}
.ant-btn.c-ffButton--help.c-ffButton--defaultColor {
  background-color: #1890ff;
  color: #fff;
  border: none;
}
.ant-btn.c-ffButton--help.c-ffButton--disabled, .ant-btn.c-ffButton--help.c-ffButton--disabled:hover {
  background-color: #bfc9d4;
  color: #fff;
}
.ant-btn.c-ffButton--help.c-ffButton:hover, .ant-btn.c-ffButton--help.c-ffButton:active {
  background-color: #6da7ff;
}
.ant-btn.c-ffButton--help .c-ffButton__icon {
  color: #fff;
}
.ant-btn.c-ffButton--withIcon {
  grid-gap: 10px;
  gap: 10px;
}
.ant-btn.c-ffButton--sm {
  height: 26px;
}
.ant-btn.c-ffButton--md {
  height: 35px;
}
.ant-btn.c-ffButton--lg {
  height: 40px;
}
.ant-btn.c-ffButton--loading .c-ffButton__icon, .ant-btn.c-ffButton--loading .c-ffButton__text {
  visibility: hidden;
}
.ant-btn.c-ffButton--loadingSkeleton .c-ffButton__icon, .ant-btn.c-ffButton--loadingSkeleton .c-ffButton__text {
  visibility: visible;
}

@-webkit-keyframes buttonLoadingAnimation {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes buttonLoadingAnimation {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}
@keyframes shine {
  to {
    background-position: 100% 0, 0 0;
  }
}
.c-ffSection {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.c-ffSection__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.c-ffSection__actions {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  margin-right: 10px;
}
.c-ffSection__title {
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  font-size: 20px;
  margin: 0px 5px 0px 0px;
}
.c-ffSection__box {
  border-radius: 6px;
  background-color: #fff;
  padding: 18px;
  border: 1px solid #e1e6eb;
  grid-gap: 15px;
  gap: 15px;
}
.c-ffLayout {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: hidden;
}
.c-ffLayout__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f7f7f9;
  overflow-y: overlay;
}
.c-ffLayout__header {
  padding: 12px 0px;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
}
.c-ffLayout__sidebarHeader {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px;
  border-bottom: 1px solid #e1e6eb;
  overflow: hidden;
  background-color: #FFF;
  justify-content: space-between;
}
.c-ffLayout__sidebarHeaderIconWrapper {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.c-ffLayout__sidebarHeaderTitle {
  font-weight: 600;
  font-size: 16px;
  color: #2a3447;
  white-space: nowrap;
  letter-spacing: -0.1px;
}
.c-ffLayout__logoIcon, .c-ffLayout__expandIcon {
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  padding-top: 4px;
}
.c-ffLayout__expandIcon {
  cursor: pointer;
  display: none;
}
.c-ffLayout__expandIcon:hover {
  background-color: #eff5fe;
}
.c-ffLayout__logoIcon {
  background-color: #da365b;
}
.c-ffLayout__collapseSidebar {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 10px;
  color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-ffLayout__collapseSidebar:hover {
  background-color: #eff5fe;
  color: unset;
}
.c-ffLayout__createNew {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}
.c-ffLayout__createPlusIcon {
  margin-top: -5px;
}
.c-ffLayout__createNewButton {
  height: 35px;
  white-space: nowrap;
  cursor: pointer;
  background-color: #48508b;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  fill: #fff;
}
.c-ffLayout__createNewButton:hover {
  background-color: #4c5ac0;
}
.c-ffLayout__createNewButton--collapsed {
  padding-right: 0px;
}
.c-ffLayout__sidebar {
  font-family: "Noto Sans" !important;
  height: 100%;
  position: relative;
  transition: max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
  max-width: 300px;
  min-width: 300px;
  background-color: #fbfcfd;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e8e8e8;
  padding: 0px;
  margin: 0px;
}
.c-ffLayout__sidebar--collapsed {
  max-width: 60px;
  min-width: 60px;
}
@media screen and (max-height: 800px) {
  .c-ffLayout__sidebar--collapsed .c-ffLayout__menuWrapper {
    grid-gap: 5px;
    gap: 5px;
  }
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__menuItemTitle {
  opacity: 0;
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__menuItemIcon {
  display: none;
  visibility: hidden;
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__menuItem {
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__menuItemSubItems {
  display: none;
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__menuFooter {
  flex-direction: column;
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__sidebarHeaderIconWrapper:hover .c-ffLayout__expandIcon {
  display: flex;
}
.c-ffLayout__sidebar--collapsed .c-ffLayout__sidebarHeaderIconWrapper:hover .c-ffLayout__logoIcon {
  display: none;
}
.c-ffLayout__sidebar--expanded .c-ffLayout__expandIcon {
  display: none;
}
.c-ffLayout__menuFooter {
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 10px;
  grid-gap: 8px;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e1e6eb;
}
@media screen and (max-height: 800px) {
  .c-ffLayout__menuFooter {
    grid-gap: 0px;
    gap: 0px;
  }
}
.c-ffLayout__unreadChatCount {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 15px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  background-color: #ff033e;
  border-radius: 6px;
}
.c-ffLayout__menuFooterItem {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 10px;
  color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-ffLayout__menuFooterItem:hover {
  background-color: #eff5fe;
  color: unset;
}
.c-ffLayout__menuWrapper {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
  padding: 0px 10px;
  overflow-y: overlay;
  overflow-x: hidden;
  height: calc(100vh - 200px);
}
.c-ffLayout__menuItemIcon {
  position: absolute;
  right: 18px;
}
.c-ffLayout__menuItemContent {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  height: 36px;
  border-radius: 10px;
  padding: 12px 9px;
  -webkit-user-select: none;
          user-select: none;
  color: rgba(0, 0, 0, 0.8509803922);
}
.c-ffLayout__menuItemContent:hover {
  background-color: #ecf2f7;
  color: rgba(0, 0, 0, 0.8509803922);
}
.c-ffLayout__menuItemContent i {
  position: absolute;
}
.c-ffLayout__menuItemContent--disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
.c-ffLayout__menuItemSubItems {
  transition: max-height 0.3s ease, min-height 0.3s ease;
  overflow: hidden;
}
.c-ffLayout__menuSubItem {
  font-weight: 400;
  font-size: 15px;
  height: 36px;
  padding: 8px 0px 8px 40px;
  border-radius: 10px;
  white-space: nowrap;
  color: #2d3257;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.c-ffLayout__menuSubItem:hover {
  background-color: #ecf2f7;
  color: #2d3257;
}
.c-ffLayout__menuSubItem.active {
  background-color: #ddedfd;
}
.c-ffLayout__menuSubItem--disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
.c-ffLayout__menuItemTitle {
  display: inline-block;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
  font-weight: 500;
  font-size: 16px;
  color: #2d3257;
  margin-left: 30px;
}
.c-ffLayout__menuItem {
  cursor: pointer;
  display: list-item;
  position: relative;
}
.c-ffLayout__menuItem.active .c-ffLayout__menuItemContent {
  background-color: #ddedfd;
}
.c-ffLayout__menuItem--open .c-ffLayout__menuItemSubItems {
  padding: 12px 0px 0px 0px;
  display: grid;
  grid-gap: 12px;
  gap: 12px;
}
.c-ffLayout__menuItem--open > .c-ffLayout__menuItemContent .c-ffLayout__menuItemIcon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: none;
}
.c-ffLayout__menuItem--closed .c-ffLayout__menuItemSubItems {
  max-height: 0px;
  min-height: 0px;
  padding: 0px !important;
  visibility: hidden;
}
.c-ffLayout__menuItem--disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.c-ffLayout__menuItemSecondLevel {
  padding-left: 30px;
}
.c-ffLayout__menuItemSecondLevel .c-ffLayout__menuItemSubItems {
  border-left: 1px solid #d4d4d4;
  margin-left: 20px;
  padding: 6px 0px 6px 0px;
  list-style-type: none;
  grid-gap: 6px;
  gap: 6px;
}
.c-ffLayout__menuItemSecondLevel .c-ffLayout__menuSubItem {
  padding: 8px 0px 8px 10px;
  margin-left: 10px;
  color: #8a90ad;
}
.c-ffLayout__menuItemSecondLevel .c-ffLayout__menuItemTitle {
  font-weight: 400;
  font-size: 15px;
}
.c-ffLayout__menuItemPopup {
  background-color: #fff;
  font-family: "Noto Sans";
  font-size: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.0509803922);
  border-radius: 8px;
  position: fixed;
  min-width: 200px;
  border: 1px solid #e1e6eb;
  padding: 10px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}
.c-ffLayout__menuItemPopup .c-ffLayout__menuItemSecondLevel {
  padding-left: 0px;
  width: 100%;
}
.c-ffLayout__menuItemPopup .c-ffLayout__menuItemTitle {
  margin-right: 40px;
}
.c-ffLayout__menuItemPopup .c-ffLayout__menuItemSubItems {
  margin-bottom: 0px;
  display: grid;
  grid-gap: 5px;
  gap: 5px;
}
.c-ffLayout__menuItemPopup .c-ffLayout__menuSubItem {
  padding-left: 10px;
  padding-right: 10px;
}
.c-ffLayout__content {
  flex: 1 1;
}
.c-ffSidePanel {
  height: 100vh;
  background: #f2f3f5;
  width: 900px;
  max-width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.1);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.c-ffSidePanel--close {
  -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
}
.c-ffSidePanel__header {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 22px;
  gap: 22px;
  padding: 0px 22px;
  border-bottom: 1px solid #e1e6eb;
  min-height: 80px;
  position: relative;
}
.c-ffSidePanel__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: none;
}
.c-ffSidePanel__backdrop--open {
  display: block;
}
.c-ffSidePanel__closeIcon {
  background-color: white;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  padding: 3px;
  position: absolute;
  left: -14px;
  color: rgba(126, 142, 173, 0.5019607843);
  cursor: pointer;
}
.c-ffSidePanel__closeIcon:hover {
  color: #e9455e;
}
.c-ffSidePanel__title {
  font-size: 21px;
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  margin: 0;
}
.c-ffSidePanel__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.c-ffSidePanel__body {
  display: flex;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
  padding: 16px 50px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.c-ffSidePanel__tabs {
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e6eb;
  border-radius: 6px;
  width: -webkit-max-content;
  width: max-content;
}
.c-ffSidePanel__tab {
  cursor: pointer;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 0px 18px 0px 25px;
  color: #8991a3;
  background-color: #f8f9fb;
}
.c-ffSidePanel__tab--active {
  background-color: #1890ff;
  color: #fff;
}
.c-ffSidePanel__tab--inactive:hover {
  color: #1890ff;
  background-color: #f1f6ff;
}
.c-ffSidePanel__tab:first-child {
  border-radius: 6px 6px 0 0;
}
.c-ffSidePanel__tab:last-child {
  border-radius: 0 0 6px 6px;
}
.c-ffSidePanel__tab:not(:last-child) {
  border-bottom: 1px solid #e1e6eb;
}
.c-ffSidePanel__tabTitle {
  width: -webkit-max-content;
  width: max-content;
  font-size: 14px;
  font-family: "Open Sans";
}
.c-ffSidePanel__children {
  display: flex;
  height: 100%;
  width: 100%;
}
.ant-radio-group.c-ffTab .ant-radio-button-wrapper {
  font-weight: 600;
}
.ant-radio-group.c-ffTab .ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-group.c-ffTab .ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-group.c-ffTab .ant-radio-button-wrapper-checked, .ant-radio-group.c-ffTab .ant-radio-button-wrapper-checked:hover {
  background-color: #229ee6;
  color: #fff;
  box-shadow: none;
}
.c-ffField {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}
.c-ffField--row {
  flex-direction: row;
}
.c-ffField--block {
  width: 100%;
}
.c-ffField--block.c-ffField--row .c-ffField__childContent {
  flex-grow: 1;
}
.c-ffField__label {
  color: #465565;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}
.c-ffField__tooltip {
  color: #1890ff !important;
  cursor: pointer;
}
.c-ffField__tooltipOverlay .ant-tooltip-inner {
  background-color: #fff;
  padding: 18px;
  color: #465565;
}
.c-ffField__tooltipOverlay .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #fff;
}
.c-ffField__childContent {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}
.c-ffField__childContent > div {
  width: 100%;
}
.c-ffField__error {
  color: #f5222d;
  margin-top: 5px;
}
.ff-circleButton__button {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: rgba(126, 142, 173, 0.5019607843);
}
.ff-circleButton__button--delete:hover {
  color: #e9455e;
}
#suspense-fallback.hidden {
  visibility: hidden;
}
#suspense-fallback-block {
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
}
#suspense-fallback-container {
  height: 6px;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 200px);
  width: 400px;
  max-width: 100%;
  z-index: 10001;
}
#suspense-fallback-preloader {
  height: 100%;
  border-radius: 3px;
  background: #da365d;
}

.ant-tooltip.popup-dialog-tooltip {
  z-index: 10001;
}
.c-layout {
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.c-layout__editorTabs {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 0px 15px;
  border-right: 1px solid #e1e6eb;
}
.c-layout__editorTabsStart {
  display: flex;
  flex-flow: row;
}
.c-layout__editorTabTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.c-layout__productTour {
  padding-right: 10px;
}
.c-layout__editorTab {
  background-color: #e8edf2;
  color: #666d7b;
  display: flex;
  align-items: center;
  border-radius: 4px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 8px;
  height: 30px;
  margin-right: 5px;
  width: 170px;
  justify-content: space-between;
}
.c-layout__editorTab:hover {
  background-color: #1890ff;
  color: white;
  transition: none;
}
.c-layout__editorTab:hover .c-layout__editorTabCloseIcon {
  color: white;
}
.c-layout__editorTabCloseIcon:hover {
  color: rgb(255, 102, 102) !important;
}
.c-layout__editorTabsWrapper {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 0px 8px;
}
.c-layout__newEditorIcon {
  fill: #1890ff;
  cursor: pointer;
}
.c-layout__newEditorIcon:hover {
  fill: #3c42ff;
}
.c-layout__closeAllTabsIcon {
  color: rgba(126, 142, 173, 0.5019607843);
  cursor: pointer;
}
.App {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.App .ant-layout.ant-layout-has-sider {
  max-height: 100%;
}
.App .ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: inherit;
  z-index: 1;
}
.App .flux-layout .ant-layout-content {
  overflow-y: auto;
  overflow-x: auto;
}

.gist-messenger-iframe {
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.3) !important;
}
@media only screen and (min-width: 800px) {
  .gist-messenger-iframe {
    min-height: 400px;
    width: 450px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .gist-messenger-iframe {
    width: 500px !important;
    min-height: 500px;
  }
}
@media only screen and (min-width: 1600px) {
  .gist-messenger-iframe {
    width: 500px !important;
    min-height: 600px;
  }
}
@media only screen and (min-width: 1800px) {
  .gist-messenger-iframe {
    width: 600px !important;
    min-height: 750px;
  }
}
@media only screen and (min-width: 2300px) {
  .gist-messenger-iframe {
    width: 600px !important;
    min-height: 1000px;
  }
}

.gist-article-sidebar-iframe > iframe {
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.3) !important;
}
@media only screen and (max-width: 1599px) {
  .gist-article-sidebar-iframe > iframe {
    width: 100% !important;
    max-width: 700px;
  }
}
@media only screen and (min-width: 1600px) {
  .gist-article-sidebar-iframe > iframe {
    width: 850px !important;
  }
}
@media only screen and (min-width: 1921px) {
  .gist-article-sidebar-iframe > iframe {
    width: 950px !important;
  }
}

.card {
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
.card.no-padding {
  padding: 0;
}
.card.no-border {
  border: none;
}
.card.is-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.send-traffic {
  position: absolute;
  z-index: 99;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  left: 310px;
  top: 10px;
  transition: all 0.2s ease-in-out;
}

.send-traffic:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.send-traffic > a {
  display: block;
  padding: 10px;
  text-decoration: none !important;
}

.send-traffic > a:visited {
  text-decoration: none;
}

.ant-scrolling-effect {
  width: 100% !important;
}

#canny-changelog-iframe {
  left: 95px !important;
  bottom: 70px !important;
  right: unset !important;
  top: unset !important;
}

.adsHeaderBorderClass {
  border-bottom: 3px solid #4285f4;
}

.funnelfluxHeaderBorderClass {
  border-bottom: 3px solid #da365b;
}
.c-Adblock {
  padding: 20px 30px;
}
.c-Adblock__btn {
  height: 40px;
  margin: 10px 0;
}
/* STYLES */
.c-Button.ant-btn {
  transition: none;
  -webkit-transition: none;
  border-radius: 4px;
  box-shadow: none;
}
.c-Button.ant-btn:hover {
  background: #6da7ff;
  color: #fff;
}
.c-Button.ant-btn:active {
  background: #1280ce;
  color: #fff;
}
.c-Button.ant-btn--bigGhost, .c-Button.ant-btn--minGhost {
  padding: 0 20px;
}
.c-Button.ant-btn--bigGhost-active, .c-Button.ant-btn--minGhost-active {
  border-color: #1890ff;
  background: #1890ff;
  color: white;
}
.c-Button.ant-btn-ghost {
  color: #1890ff;
  border: 2px solid #1890ff;
}
.c-Button--loading .c-Button__text {
  visibility: hidden;
}
.c-Button--disabled {
  background-color: #bfc9d4 !important;
  border-color: #bfc9d4 !important;
  color: #fff !important;
  cursor: not-allowed;
  pointer-events: none;
}
.c-Button-switch.ant-switch {
  width: 44px;
}

.c-editModeSwitch__label {
  cursor: pointer;
}
.c-editModeSwitch__label--active {
  color: #4b606d;
}
.c-editModeSwitch__label--inactive {
  cursor: pointer;
  color: #89959c;
}
.c-editModeSwitch__switch {
  width: 44px;
}
.c-editModeSwitch__switch::after {
  transition-duration: 0.2s;
}

#c-switchButton .c-switchButton__switch {
  margin-right: 10px;
  width: 44px;
}

.c-checkboxButton {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.c-checkboxButton__checkbox .ant-checkbox-inner::after {
  border-width: 1px;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -60%);
          transform: rotate(45deg) scale(1) translate(-50%, -60%);
}

.c-buttonLoading {
  height: 20px;
  margin: -20px auto auto auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.c-buttonLoading__loading {
  font-size: 25px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.c-buttonLoading__loading:nth-child(1) {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
}
.c-buttonLoading__loading:nth-child(2) {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.flux-icon-refresh {
  width: 28px;
  cursor: pointer;
  transition: 0.3s ease;
}
.flux-icon-refresh:hover {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.flux-icon-refresh:hover .first-path {
  fill: #1890ff !important;
}

.archive-container,
.delete-container,
.copy-container,
.builder-container,
.edit-container,
.quickstat-container {
  width: 100%;
  position: relative;
}

.copy-container:hover svg g {
  stroke: white;
  fill: #1890ff;
}

.delete-container svg {
  position: relative;
  left: -1px;
}

.remove-container {
  width: calc(100% + 1px) !important;
  height: calc(100% + 2px) !important;
  position: absolute;
  top: 0;
  left: -1px;
  background: transparent !important;
}
.remove-container i {
  background: transparent !important;
}
.remove-container svg {
  fill: rgb(141, 154, 168) !important;
}

.ff-tooltip.switch-button-tooltip {
  max-width: 500px;
}
.ff-tooltip.switch-button-tooltip .ant-tooltip-inner {
  width: 500px;
}

.c-iconedButton {
  display: flex;
  border: 1px solid #1890ff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  color: #1890ff;
  cursor: pointer;
  height: 35px;
  justify-content: space-around;
  align-items: center;
  padding: 0px 7px;
  grid-gap: 7px;
  gap: 7px;
}
.c-iconedButton--icon {
  font-size: 20px;
}
.c-iconedButton--icon svg {
  fill: #1890ff;
}
.c-dynamicSidebar {
  height: 100%;
  background-color: #f2f3f5;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.3s ease;
}
.c-dynamicSidebar--isOpen {
  box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.c-dynamicSidebar--withTitle.c-dynamicSidebar--withTab {
  display: flex;
  flex-direction: column;
}
.c-dynamicSidebar--withTitle.c-dynamicSidebar--withTab .c-dynamicSidebar__bodyTabsWrapper {
  padding: 0px 20px 0px 30px;
  height: calc(100% - 80px);
}
@media (max-width: 768px) {
  .c-dynamicSidebar--withTitle.c-dynamicSidebar--withTab .c-dynamicSidebar__bodyTabsWrapper {
    padding: 0px 15px;
  }
}
.c-dynamicSidebar--withoutTab .c-dynamicSidebar__body {
  margin-right: 0;
}
.c-dynamicSidebar__portal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 3;
}
.c-dynamicSidebar__overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
  visibility: visible;
}
.c-dynamicSidebar__overlay--hidden {
  opacity: 0;
  visibility: hidden;
}
.c-dynamicSidebar__bodyTabsWrapper {
  display: flex;
  flex-direction: row-reverse;
  max-height: calc(100vh - 105px);
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
  overflow-y: hidden;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;
  padding: 0px 20px 20px 30px;
}
@media (max-width: 768px) {
  .c-dynamicSidebar__bodyTabsWrapper {
    grid-gap: 15px;
    gap: 15px;
    padding: 0px 0px 0px 15px;
  }
}
.c-dynamicSidebar__body {
  width: 100%;
  overflow: auto;
}
.c-dynamicSidebar__tabs {
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  border: 1px solid #e1e6eb;
  background-color: #f8f9fb;
  z-index: 10;
  border-radius: 6px;
}
.c-dynamicSidebar__tabs--disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.c-dynamicSidebar__tab {
  cursor: pointer;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 0px 18px 0px 25px;
  color: #8991a3;
  background-color: #f8f9fb;
}
.c-dynamicSidebar__tab .c-dynamicSidebar__tabText {
  color: #5e646f;
}
.c-dynamicSidebar__tab:hover {
  color: #1890ff;
  background-color: #f1f6ff;
}
.c-dynamicSidebar__tab:hover .c-dynamicSidebar__tabText {
  color: #1890ff;
}
.c-dynamicSidebar__tab--highlight {
  -webkit-animation: animate-bg 1.5s;
          animation: animate-bg 1.5s;
}
.c-dynamicSidebar__tab--withError {
  -webkit-animation: animate-bg 1.5s;
          animation: animate-bg 1.5s;
}
.c-dynamicSidebar__tab--active {
  background-color: #1890ff;
  color: #fff;
}
.c-dynamicSidebar__tab--active .c-dynamicSidebar__tabText {
  color: #fff;
}
.c-dynamicSidebar__tab--active:hover {
  color: #fff;
  background-color: #1890ff;
}
.c-dynamicSidebar__tab--active:hover .c-dynamicSidebar__tabText {
  color: #fff;
}
.c-dynamicSidebar__tab--disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #e1e6eb;
  opacity: 0.5;
}
.c-dynamicSidebar__tab:not(:last-child) {
  border-bottom: 1px solid #e1e6eb;
}
.c-dynamicSidebar__tab:first-child {
  border-radius: 6px 6px 0px 0px;
}
.c-dynamicSidebar__tab:last-child {
  border-radius: 0px 0px 6px 6px;
}
.c-dynamicSidebar__tabText {
  font-family: "Open Sans";
  font-size: 14px;
  color: inherit;
  width: -webkit-max-content;
  width: max-content;
}
.c-dynamicSidebar__header {
  height: 80px;
  min-width: 420px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0px 30px;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .c-dynamicSidebar__header {
    margin-bottom: 15px;
  }
}
.c-dynamicSidebar__headerText {
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  margin: 0;
}
.c-dynamicSidebar__copyIds {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}
.c-dynamicSidebar__copyIdBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: lightgray;
  border: 1px solid transparent;
  padding: 5px 15px;
  border-radius: 6px;
  grid-gap: 10px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.c-dynamicSidebar__copyIdBox:hover {
  border: 1px solid #e1e6eb;
  background-color: #f9fbfd;
}
.c-dynamicSidebar__copyIdBox:hover .c-dynamicSidebar__copyIdIcon {
  color: #1890ff;
}
.c-dynamicSidebar__copyIdText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}
.c-dynamicSidebar__copyIdValue {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}
.c-dynamicSidebar__copyIdTextValue {
  display: flex;
  flex-direction: column;
}
.c-dynamicSidebar__closeIcon {
  position: absolute;
  left: -12px;
  top: 25px;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

#dynamicSidebar-swapPages {
  width: 600px;
}

.builderPageGroupSidebar #dynamicSidebar-pagegroupForm {
  width: 100%;
  min-width: 800px;
  max-width: 1000px;
}

.pageGroupSidebar #dynamicSidebar-pagegroupForm {
  width: 100%;
  min-width: 600px;
  max-width: 850px;
}

#dynamicSidebar-partnerForm {
  width: 1100px;
}

#dynamicSidebar-partnerForm,
#dynamicSidebar-partnerDetailForm {
  background-color: #fff;
}
#dynamicSidebar-partnerForm .c-dynamicSidebar__body,
#dynamicSidebar-partnerDetailForm .c-dynamicSidebar__body {
  margin: 0;
  max-width: unset;
  height: 100%;
}
#dynamicSidebar-partnerForm .c-dynamicSidebar__bodyTabsWrapper,
#dynamicSidebar-partnerDetailForm .c-dynamicSidebar__bodyTabsWrapper {
  padding: 0;
  margin: 0;
  max-width: unset;
  max-height: unset;
  height: 100%;
}
#dynamicSidebar-partnerForm .partner-detail-sidebar-body li,
#dynamicSidebar-partnerDetailForm .partner-detail-sidebar-body li {
  margin-left: 30px;
  padding-bottom: 10px;
}

#dynamicSidebar-funnelGroupForm {
  width: 100%;
  min-width: 800px;
  max-width: 1000px;
}

#dynamicSidebar-quickstats {
  width: 95%;
}
#dynamicSidebar-quickstats .c-dynamicSidebar__bodyTabsWrapper {
  max-width: unset;
  max-height: calc(100vh - 85px);
  padding: 0;
}
#dynamicSidebar-quickstats .c-dynamicSidebar__body {
  height: calc(100vh - 90px);
  margin: 0;
  max-width: unset;
}
#dynamicSidebar-quickstats .c-dynamicSidebar__header {
  margin-bottom: 0;
}

#dynamicSidebar-conditionForm {
  width: 100%;
  min-width: 800px;
  max-width: 1000px;
}

#dynamicSidebar-externalUrlForm {
  width: 100%;
  min-width: 800px;
  max-width: 1000px;
}

#dynamicSidebar-funnelForm {
  width: 100%;
  min-width: 750px;
  max-width: 1000px;
}

#dynamicSidebar-landerForm {
  width: 100%;
  min-width: 850px;
  max-width: 1000px;
}

#dynamicSidebar-offerForm {
  width: 100%;
  min-width: 850px;
  max-width: 1100px;
}
#dynamicSidebar-offerForm .c-dynamicSidebar__bodyTabsWrapper {
  max-width: 1100px;
}

#dynamicSidebar-offersourceForm {
  width: 100%;
  min-width: 850px;
  max-width: 1000px;
}

#dynamicSidebar-rotatorForm {
  width: 100%;
  min-width: 400px;
  max-width: 1000px;
}

#dynamicSidebar-tableSettings {
  width: 100%;
  min-width: 700px;
  max-width: 800px;
}

#dynamicSidebar-trafficsourceForm {
  width: 100%;
  min-width: 850px;
  max-width: 1150px;
}
#dynamicSidebar-trafficsourceForm .c-dynamicSidebar__bodyTabsWrapper {
  max-width: 1100px;
}

#dynamicSidebar-simpleFlowsForm {
  width: 100%;
  min-width: 800px;
  max-width: 1000px;
}

#dynamicSidebar-simpleFlowsPathEditor {
  width: 100%;
  min-width: 500px;
  max-width: 850px;
}
#dynamicSidebar-simpleFlowsPathEditor .c-dynamicSidebar__bodyTabsWrapper {
  grid-gap: 0px;
  gap: 0px;
}

#dynamicSidebar-simpleFlowsRuleEditor {
  width: 100%;
  min-width: 500px;
  max-width: 850px;
}
#dynamicSidebar-simpleFlowsRuleEditor .c-dynamicSidebar__bodyTabsWrapper {
  grid-gap: 0px;
  gap: 0px;
}

#dynamicSidebar-categoryForm {
  width: 100%;
  min-width: 300px;
  max-width: 700px;
}

#dynamicSidebar-confirmForm {
  width: 100%;
  min-width: 450px;
  max-width: 600px;
}

@-webkit-keyframes animate-bg {
  0% {
    background-color: #f8f9fb;
  }
  25% {
    background-color: #ff7575;
  }
  50% {
    background-color: #ff7575;
  }
  75% {
    background-color: #ff7575;
  }
  100% {
    background-color: #f8f9fb;
  }
}

@keyframes animate-bg {
  0% {
    background-color: #f8f9fb;
  }
  25% {
    background-color: #ff7575;
  }
  50% {
    background-color: #ff7575;
  }
  75% {
    background-color: #ff7575;
  }
  100% {
    background-color: #f8f9fb;
  }
}
.c-formSectionBox {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #fff;
  overflow-x: hidden;
  margin-bottom: 20px;
}
.c-formSectionBox__title {
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  font-size: 20px;
  margin: 0px 5px 0px 0px;
}
.c-formSectionBox__title--withPaddig {
  padding: 20px 0px 0px 20px;
}
.c-formSectionBox--withBoxPadding {
  padding: 20px;
}
.c-formSectionBox--withBorder {
  border: 1px solid #e1e6eb;
}

.c-scrollableContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}
.c-scrollableContainer::-webkit-scrollbar {
  width: 2px;
  border-radius: 3.5px;
  background-color: #eff4f8;
}
.c-scrollableContainer::-webkit-scrollbar-track {
  width: 2px;
  border-radius: 3.5px;
  background-color: #eff4f8;
}
.c-scrollableContainer::-webkit-scrollbar-thumb {
  width: 2px;
  border-radius: 3.5px;
  background-color: #229ee6;
}
.c-scrollableContainer > * {
  min-height: 32px;
}
.c-modalButtonGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
}
.c-modalButtonGroup.settings-buttons .c-Button--ok,
.c-modalButtonGroup.settings-buttons .c-Button--cancel {
  z-index: 11;
}
.c-modalButtonGroup .c-Button {
  width: auto;
  height: 34px;
  font-weight: 700;
  font-style: normal;
  font-family: "Open Sans";
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 13px;
  border-radius: 6px;
}
.c-modalButtonGroup .c-Button span {
  margin-bottom: 2px;
}
.c-modalButtonGroup .c-Button--cancel {
  background-color: #e1e6eb;
  color: #5e646f;
  border-color: transparent;
}
.c-modalButtonGroup.redStyleButton-ok .c-Button--ok {
  background: #e9455e;
  border-color: #e9455e;
  color: #fff;
}
.c-modalButtonGroup.redStyleButton-ok .c-Button--ok:hover {
  background: #ee5a74;
  border-color: #ee5a74;
}
.c-modalButtonGroup.redStyleButton-ok .c-Button--ok:disabled {
  border-color: transparent;
}
.c-modalButtonGroup.redStyleButton-cancel .c-Button--cancel {
  background: #e9455e;
  border-color: #e9455e;
  color: #fff;
}
.c-modalButtonGroup.redStyleButton-cancel .c-Button--cancel:hover {
  background: #ee5a74;
  border-color: #ee5a74;
}
.c-modalButtonGroup.redStyleButton-cancel .c-Button--cancel:disabled {
  border-color: transparent;
}

.c-addRemoveGroup {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.c-addRemoveGroup__child {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  grid-gap: 10px;
  gap: 10px;
}
.c-addRemoveGroup__child > .c-addRemoveGroup__archiveBtn:hover svg {
  fill: #fb5c35;
}
.c-addRemoveGroup__child__body {
  display: flex;
  flex-direction: column;
}
.c-addRemoveGroup__child__body__footer {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.c-addRemoveGroup__child__body__footer__newBtn {
  z-index: 69;
  padding: 0 5px;
  border-radius: 50%;
  background: #fff;
  display: inline-block;
}
.c-addRemoveGroup__child__body__footer::before {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  display: inline-block;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #c2d2d9;
}

.c-andOrOperator {
  display: flex;
  border: 1px solid #c2d2d9;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  height: 35px;
  width: 90px;
  align-content: center;
}
.c-andOrOperator--or {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-andOrOperator--and {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-andOrOperator--active {
  background-color: #1890ff;
  color: #fff;
}
.partner-sidebar {
  display: flex;
  height: 100%;
}
.partner-categories {
  min-width: 250px;
  border-right: 1px solid #e1e6eb;
  height: 100%;
}
.partner-categories ul {
  list-style-type: none;
}
.partner-categories ul li {
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
  justify-content: center;
  border-right: 2px solid transparent;
}
.partner-categories ul li:first-child {
  font-weight: 700;
}
.partner-categories ul li:not(:first-child) {
  cursor: pointer;
}
.partner-categories ul li:not(:first-child)[data-active=true] {
  border-right-color: red;
}
.partner-categories ul li:not(:first-child):hover {
  background-color: #f5f8fa;
}
.partner-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.partner-main-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  min-height: 80px;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
}
.partner-main-header div:first-child {
  background-image: url(/static/media/funnelflux-symbol.5e448864.svg);
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: left;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
}
.partner-main-header div:first-child h2 {
  margin: 0;
  font-weight: 600;
}
.partner-main-header .ant-input-affix-wrapper {
  width: 200px;
}
.partner-main-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}
.partner-main-list-card {
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: rgba(229, 229, 229, 0.0901960784);
  display: flex;
  margin-bottom: 25px;
}
.partner-main-list-card:hover {
  box-shadow: 0px 0px 3px 2px rgba(255, 0, 0, 0.5);
}
.partner-main-list-card-img {
  flex: 0.25 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-main-list-card-img img {
  max-width: 150px;
}
.partner-main-list-card-description {
  flex: 0.75 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding: 25px 10px;
  max-width: 700px;
}
.partner-main-list-card-description-top {
  display: flex;
  align-items: center;
}
.partner-main-list-card-description-top h2 {
  font-weight: 700;
  margin: 0;
}
.partner-main-list-card-description-top span {
  background-color: #520fd1;
  color: #fff;
  width: 120px;
  text-align: center;
  border-radius: 20px;
  margin-left: 20px;
}
.partner-main-list-card-description-text {
  max-height: 66px;
  min-height: 66px;
  overflow: hidden;
  position: relative;
  margin: 15px 0px;
}
.partner-main-list-card-description-text p,
.partner-main-list-card-description-text pre {
  line-height: 22px;
  margin: 0;
}
.partner-main-list-card-description-text pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.partner-main-list-card-description > span {
  font-weight: 700;
}
.partner-main-list-card .arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}
.partner-detail-sidebar {
  min-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.partner-detail-sidebar-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
  width: 100%;
}
.partner-detail-sidebar-header h2 {
  margin: 0;
}
.partner-detail-sidebar-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 25px 0px;
}
.partner-detail-sidebar-body img {
  max-width: 150px;
  margin-bottom: 25px;
}
.partner-detail-sidebar-body > div {
  max-width: 500px;
  margin-bottom: 25px;
  text-align: left;
}
.partner-detail-sidebar-body pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.partner-detail-sidebar-body > span {
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  font-weight: 700;
}
.partner-detail-sidebar-body a {
  height: 45px;
  padding: 0px 20px;
  min-width: 150px;
  background-color: #1890ff;
  color: #fff;
  text-align: center;
  line-height: 45px;
  border-radius: 7px;
}
.partner-detail-sidebar-body a:hover {
  color: #fff;
}
.has-error .ant-input-affix-wrapper,
.has-error.ant-input:not([disabled]):hover,
.has-error .ant-select-selector,
.has-error.ant-select-selection:not([disabled]):hover {
  border-color: #f5222d !important;
}

.multiple-select {
  margin-bottom: 14px !important;
  height: auto !important;
}

.big-select-dropdown {
  width: auto !important;
}

.ant-input,
.ant-calendar-picker-input {
  font-size: 13px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-input input::-webkit-input-placeholder, .ant-calendar-picker-input input::-webkit-input-placeholder {
  color: #939aa1 !important;
}
.ant-input input::placeholder,
.ant-calendar-picker-input input::placeholder {
  color: #939aa1 !important;
}

.ant-slider {
  margin-left: 0 !important;
}
.ant-slider .ant-slider-step {
  background: #efefef;
}
.ant-slider .ant-slider-track {
  background: #1890ff;
  z-index: 100;
}
.ant-slider .ant-slider-handle {
  z-index: 110;
  border-color: #1890ff;
  margin-left: 0;
}

.ant-calendar-footer-btn .ant-calendar-range-quick-selector .ant-tag {
  margin-right: 3px;
  cursor: pointer;
}

.textarea-type-wrapper {
  position: relative;
}
.textarea-type-wrapper .textarea-suffix {
  position: absolute;
  top: 18px;
  z-index: 2;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}
.textarea-type-wrapper textarea {
  margin-bottom: 0 !important;
  resize: none;
  padding: 8px 7px 0 10px !important;
}

.input-type-textarea {
  width: 100%;
  position: relative;
}
.input-type-textarea .textarea-suffix {
  position: absolute;
  top: 18px;
  z-index: 2;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}
.input-type-textarea textarea {
  margin-bottom: 0 !important;
  resize: none;
  padding: 8px 7px 8px 10px !important;
}

textarea {
  word-break: break-all;
}

.c-label {
  margin-bottom: 6px;
  color: #4b606d;
  font-size: 14px;
  display: block;
}
.c-label .flux-icon-information {
  margin-left: 5px;
}

.ant-picker {
  border-radius: 4px !important;
}

.ant-input-affix-wrapper,
textarea {
  border-radius: 4px !important;
}

.ant-select {
  width: 100%;
  font-size: 13px !important;
}
.ant-select-selector {
  height: 100% !important;
  border-radius: 4px !important;
}
.ant-select-middle:not(.ant-select-multiple) {
  height: 35px;
}
.ant-select-middle:not(.ant-select-multiple).ant-select-single {
  line-height: 35px !important;
}
.ant-select-middle:not(.ant-select-multiple) .ant-select-selection-item {
  line-height: 35px !important;
}
.ant-select-middle:not(.ant-select-multiple) .ant-select-selection-placeholder {
  line-height: 35px !important;
}
.ant-select-sm {
  height: 30px;
}
.ant-select-sm.ant-select-single {
  line-height: 30px !important;
}
.ant-select-sm .ant-select-selection-item {
  line-height: 30px !important;
}
.ant-select-sm .ant-select-selection-placeholder {
  line-height: 30px !important;
}
.ant-select--all {
  color: #393939;
  font-weight: 600;
  border-bottom: solid 1px #e8e8e8;
}
.ant-select--all.ant-select--hiddenItem {
  display: none;
}
.ant-select--all:after {
  content: " ";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  right: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
.ant-select-multiple.ant-select-middle {
  height: 34px;
}
.ant-select-multiple .ant-select-selection-overflow-item {
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}
.ant-select-multiple .ant-select-selection-item {
  background-color: #1890ff !important;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 12px;
  font-weight: 400;
}
.ant-select-multiple .ant-select-selection-item .anticon-close {
  color: #fff;
}
.ant-select-item {
  font-size: 13px !important;
  font-weight: 400;
}
.ant-select-item .ant-select-item-option-content {
  font-weight: inherit;
  font-size: inherit;
}
.ant-select-item.ant-select-item-group {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: 13px;
  font-weight: 600;
  color: #555555;
}
.ant-select-dropdown {
  border-radius: 4px;
}
.ant-select-dropdown .ant-select-item {
  transition: none;
  -webkit-transition: none;
  color: rgba(0, 0, 0, 0.65);
}
.ant-select-dropdown .ant-select-item-option-selected {
  font-weight: 600;
}
.ant-select-dropdown .uncategorized {
  border-bottom: 1px solid #d9d9d9;
}
.ant-select-dropdown .select-option-bottom-border {
  border-bottom: 1px solid #d9d9d9;
}
.ant-select-dropdown .listTitle {
  border-bottom: 1px solid #d9d9d9;
  cursor: default !important;
  background-color: white !important;
}
.ant-select-dropdown .create-new-view {
  color: #1890ff !important;
}
.ant-select-dropdown .ant-select-item.ant-select-item-group {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ant-select-dropdown .italic-text {
  font-style: italic;
}
.ant-select.expand-to-max-height {
  height: auto !important;
}
.ant-select.expand-to-max-height .ant-select-selection--multiple,
.ant-select.expand-to-max-height .ant-select-selection__rendered,
.ant-select.expand-to-max-height ul {
  position: relative !important;
  height: auto !important;
}

.ant-select-empty {
  padding-left: 6px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 14px !important;
}

.ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-list {
  list-style: none;
}
.ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-list .ant-select-item.ant-select-item-group {
  padding: 0 20px;
}
.ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item-group-list {
  list-style: none;
}
.ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item {
  padding: 5px 40px;
}
.ant-select-dropdown-menu-item-group .ant-select-item.ant-select-item-group {
  white-space: nowrap;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: #229ee6 !important;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-preset .ant-tag {
  margin: 0px 2px;
  font-size: 12px;
}
.c-subscriptionAlert__wrapper {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 10px;
  max-width: 430px;
  padding: 10px;
  z-index: 1000;
  border: 2px solid #ddafaf;
  font-size: 14px;
}
.c-subscriptionAlert__wrapper--info {
  background-color: #66bef0;
  border: 2px solid #92acdd;
}
.c-subscriptionAlert__wrapper--warning {
  background-color: #ffcece;
}
.c-subscriptionAlert__wrapper p,
.c-subscriptionAlert__wrapper a {
  display: inline;
}
.c-subscriptionAlert__link {
  color: #3743c0;
  text-decoration: underline;
}
.c-subscriptionAlert__link:hover {
  color: #3743c0;
  text-decoration: underline;
}
.c-nodeDelete__contentWrapper {
  padding: 20px 22px 29px 25px;
}
.c-nodeDelete__text {
  line-height: 26px;
}
.c-nodeDelete__text--strong {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  color: #4b606d;
}
.c-conditionDelete__contentWrapper {
  padding: 20px 30px 30px 30px;
}
.c-conditionDelete__text {
  line-height: 26px;
  color: #5a6a7b;
}
.c-conditionDelete__text--bold {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  color: #4b606d;
}
.c-createNewAssetModal {
  padding: 30px;
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;
  gap: 16px;
}
.c-createNewAssetModal__item {
  padding: 8px 24px;
  height: 120px;
  width: 250px;
  border: 1px solid transparent;
  border-radius: 8px;
  border: solid 1px #dadada;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 0px 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 600;
  color: #444;
  cursor: pointer;
}
.c-createNewAssetModal__item:hover {
  border-color: rgba(24, 143, 255, 0.1882352941);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 12px 0px;
  background-color: rgba(24, 143, 255, 0.0588235294);
  color: #188fff;
}
.edit-dns-modal .record-creator-wrapper {
  padding: 25px;
}

.record-creator-wrapper p {
  margin-bottom: 20px;
}
.record-creator-wrapper .help-text {
  font-weight: 600;
  margin: 1px;
}
.record-creator-wrapper .filled-help-text {
  color: rgb(146, 151, 155);
}
.record-creator-wrapper .empty-help-text {
  color: black;
}
.record-creator-wrapper .actions {
  display: flex;
  margin-top: 15px;
}
.record-creator-wrapper .actions button {
  height: 40px !important;
}
.record-creator-wrapper .fields-wrapper {
  display: flex;
  flex-flow: wrap;
}
.record-creator-wrapper .fields-wrapper .break {
  flex-basis: 100%;
  margin-top: 20px;
}
.record-creator-wrapper .fields-wrapper .proxy-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  cursor: pointer;
}
.record-creator-wrapper .fields-wrapper .proxy-wrapper img {
  box-sizing: border-box;
  width: 40px;
  height: 100%;
}
.record-creator-wrapper .fields-wrapper .proxy-wrapper span {
  margin-left: 10px;
}
.record-creator-wrapper .fields-wrapper .field {
  margin: 0px 10px 0px 0px;
}
.record-creator-wrapper .fields-wrapper .field .label {
  margin-bottom: 5px;
}
.record-creator-wrapper .fields-wrapper .field .label span {
  margin-left: 5px;
  color: red;
}
.record-creator-wrapper .fields-wrapper .main-field {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.record-creator-wrapper .fields-wrapper .main-field > div {
  width: 100%;
}
.record-creator-wrapper .fields-wrapper .sub-field {
  display: flex;
  flex-direction: column;
  flex: 0.25 1;
}
.record-creator-wrapper .fields-wrapper .sub-field .label {
  visibility: hidden;
}
.record-creator-wrapper .fields-wrapper .sub-field > div {
  width: 100%;
}
.record-creator-wrapper .fields-wrapper .description {
  margin-top: 5px;
  font-size: 12px;
}
.record-creator-wrapper .fields-wrapper .Type {
  width: 150px;
  flex: inherit !important;
}
.record-creator-wrapper .fields-wrapper .degrees,
.record-creator-wrapper .fields-wrapper .proxied,
.record-creator-wrapper .fields-wrapper .min,
.record-creator-wrapper .fields-wrapper .sec,
.record-creator-wrapper .fields-wrapper .direction,
.record-creator-wrapper .fields-wrapper .meters {
  width: 150px;
  flex: inherit !important;
}
.record-creator-wrapper .fields-wrapper .proxied {
  width: 130px;
  flex: inherit !important;
}
.delete-dns-modal {
  padding: 25px;
  display: flex;
  flex-direction: column;
}
.delete-dns-modal p {
  margin-bottom: 20px;
}
.delete-dns-modal table {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
.delete-dns-modal table thead {
  display: flex;
  flex: 1 1;
}
.delete-dns-modal table thead th {
  flex: 1 1;
  background: #eaebeb;
  text-align: left;
  padding: 10px 10px;
}
.delete-dns-modal table thead th:first-child {
  max-width: 100px;
}
.delete-dns-modal table tbody tr {
  flex: 1 1;
  display: flex;
}
.delete-dns-modal table tbody tr td {
  flex: 1 1;
  text-align: left;
  padding: 5px 10px;
  overflow: hidden;
  word-wrap: break-word;
}
.delete-dns-modal table tbody tr td:first-child {
  font-weight: 700;
  max-width: 100px;
}
.save-discard-form .ff-button-cancel {
  border: none !important;
  color: #ffffff;
  background-color: #f57c5f;
}
.save-discard-form .ff-button-cancel:hover {
  background-color: #fb5c35;
}
.form-reportingAddView .text {
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  color: #4b606d;
  margin: 0;
}
.form-reportingAddView .input-wrapper {
  margin-bottom: 30px;
}
.form-reportingAddView .input-wrapper p {
  margin-bottom: 9px;
}
.form-reportingAddView .input-wrapper .ant-input {
  width: 100%;
}
.c-codeSnippet {
  background-color: #f4f5f7;
  padding: 5px 5px 5px 10px;
  border-radius: 4px;
  overflow-y: auto;
  position: relative;
  overflow: hidden;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
}
.c-codeSnippet__maxContent {
  max-height: unset;
}
.c-codeSnippet__errorIcon {
  color: #f5222d;
}
.c-codeSnippet__code {
  margin: 0;
  color: #172b4d;
  font-size: 12px;
  font-weight: 400;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  word-break: break-all;
  font-family: SFMono;
}
.c-codeSnippet__copyBtnBox {
  padding: 6px 4px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
}
.c-codeSnippet__copyBtnBox:hover {
  background: #E2E4E9;
}
.c-codeSnippet__copyBtn {
  display: flex;
}
.c-codeSnippet--disabled {
  cursor: not-allowed;
}
.c-codeSnippet--disabled .c-codeSnippet__copyBtn {
  pointer-events: none;
  color: gray;
}
.c-codeSnippet--disabled .c-codeSnippet__copyBtnBox {
  cursor: not-allowed;
  pointer-events: none;
}
.f-domainSettings {
  display: flex;
  flex-direction: column;
}
.f-domainSettings__row {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.f-domainSettings__row:not(:last-child) {
  margin-bottom: 12px;
}
.f-domainSettings__codeSnippet {
  flex: 1 1;
}
.f-domainSettings__finaltext {
  margin-bottom: 0;
}
.f-domainSettings__tag {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding: 5px 16px;
  line-height: 14px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}
.f-domainSettings__tag--pending {
  background-color: #f99259;
}
.f-domainSettings__tag--failed {
  background-color: #bd1717;
}
.f-domainSettings__tag--active {
  background-color: #0091ea;
}
.f-domainSettings__tag--disabled {
  background-color: #cccccc;
}
.c-landerForm__pageName {
  width: 70%;
}
.c-landerForm__category {
  flex: 1 1;
}
.c-landerForm__categorySelect {
  width: 155px;
}
.c-landerForm__finalUrl {
  width: 100%;
}
.cform-offer__codeSnippetTitle {
  font-size: 18px;
  font-weight: 600;
  color: #5d6d88;
}
.cform-offer__javascriptTracking {
  grid-gap: 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}
.cform-offer__javascriptTracking h3, .cform-offer__javascriptTracking p {
  margin: 0;
}
.cform-offer__javascriptTrackingTitle {
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  font-size: 18px;
}
.cform-offer__javascriptTrackingGlobalSection, .cform-offer__javascriptTrackingDefaultParamsSection {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.cform-offer__javascriptTrackingGlobalSection > p, .cform-offer__javascriptTrackingDefaultParamsSection > p {
  margin: 0;
}
.c-inputSelect {
  position: relative;
  display: flex;
  flex-direction: column;
}
.c-inputSelect--open .ant-select {
  top: 0;
  visibility: visible;
}
.c-inputSelect .ant-select {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 7;
  width: 100%;
  background-color: red;
  visibility: hidden;
}
.c-inputSelect .ant-input-affix-wrapper {
  z-index: 10;
  position: relative;
}
.c-offersourceForm__categorySelect {
  width: 150px;
}
.c-templateSelector {
  margin-bottom: 20px;
}
.c-templateSelector__search {
  background-color: #fff;
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 6px;
}
.c-templateSelector__templates {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  gap: 15px;
}
.c-templateSelector__template {
  height: 170px;
  flex-direction: column;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
}
.c-templateSelector__template--img {
  width: inherit;
  max-width: 60%;
}
.c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_1, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_2, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_3, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_15, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_29 {
  max-width: 45%;
}
.c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_20, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_21, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_32, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_35, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_39, .c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_46 {
  max-width: 70%;
}
.c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_48 {
  max-width: 90%;
}
.c-templateSelector__trafficsource .c-templateSelector__template--img--traffic_source_53 {
  max-width: 50%;
}
.c-templateSelector__offersource .c-templateSelector__template--img--offer_source_49, .c-templateSelector__offersource .c-templateSelector__template--img--offer_source_57 {
  max-width: 50%;
}
.c-templateSelector__offersource .c-templateSelector__template--img--offer_source_1, .c-templateSelector__offersource .c-templateSelector__template--img--offer_source_46, .c-templateSelector__offersource .c-templateSelector__template--img--offer_source_64, .c-templateSelector__offersource .c-templateSelector__template--img--offer_source_68 {
  max-width: 45%;
}
.c-templateSelector__offersource .c-templateSelector__template--img--offer_source_27, .c-templateSelector__offersource .c-templateSelector__template--img--offer_source_47 {
  max-width: 30%;
}
.c-ConditionNew__ribbon {
  right: 10px !important;
  padding: 2px 16px 2px 6px !important;
  height: 26px !important;
}
.c-ConditionNew__route {
  border-bottom: 1px solid #c2d2d9;
  padding: 12px;
  grid-gap: 12px;
  gap: 12px;
  background-color: #fff;
}
.c-ConditionNew__route--moreThanOneGroup .c-ConditionNew__routeOperator::before {
  height: 3px;
  width: 10px;
  background-color: #0089ff;
  position: absolute;
  left: -10px;
  top: 10px;
  content: " ";
}
.c-ConditionNew__route--moreThanOneGroup .c-ConditionNew__groupsWrapper::before {
  height: calc(100% - 70px);
  width: 3px;
  background-color: #0089ff;
  position: absolute;
  left: 5px;
  top: 25px;
  content: " ";
}
.c-ConditionNew__route--moreThanOneGroup .c-ConditionNew__group::before {
  height: 3px;
  width: 10px;
  background-color: #0089ff;
  position: absolute;
  left: -10px;
  top: calc(100% - 25px);
  content: " ";
}
.c-ConditionNew__groupsWrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 15px;
  border: 1px solid #c2d2d9;
  border-radius: 4px;
  background: #f4f8fc;
  position: relative;
}
.c-ConditionNew__routeOperator {
  position: relative;
}
.c-ConditionNew__groupsHeader {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}
.c-ConditionNew__group {
  border: 1px solid #c2d2d9;
  background-color: #fff;
  padding: 10px 15px 0px 15px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
}
.c-ConditionNew__group--moreThenOneRule .c-ConditionNew__groupOperator {
  position: relative;
}
.c-ConditionNew__group--moreThenOneRule .c-ConditionNew__groupOperator::before {
  height: 3px;
  width: 10px;
  background-color: #fda74e;
  position: absolute;
  left: -10px;
  top: 11px;
  content: " ";
}
.c-ConditionNew__group--moreThenOneRule .c-ConditionNew__groupInnerContent::before {
  height: calc(100% - 53px);
  width: 3px;
  background-color: #fda74e;
  position: absolute;
  left: 5px;
  top: 23px;
  content: " ";
}
.c-ConditionNew__group--moreThenOneRule .c-ConditionNew__selectField {
  position: relative;
}
.c-ConditionNew__group--moreThenOneRule .c-ConditionNew__selectField::before {
  height: 3px;
  width: 10px;
  background-color: #fda74e;
  position: absolute;
  left: -10px;
  top: 15px;
  content: " ";
}
.c-ConditionNew__selectCategory {
  width: 100%;
}
.c-ConditionNew__addIcon {
  color: #1890ff;
  cursor: pointer;
}
.c-ConditionNew__index {
  min-width: 22px;
  min-height: 22px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #0958d9;
  background-color: #e6f4ff;
  border: 1px solid #b9ddff;
  border-radius: 50%;
}
.c-ConditionNew__index--default {
  color: #8c939f;
}
.c-ConditionNew__selectValues, .c-ConditionNew__inputValues {
  flex: 1 1;
}
.c-ConditionNew__selectField {
  width: 200px;
}
.c-ConditionNew__deleteIcon {
  cursor: pointer;
  color: #8c939f;
}
.c-ConditionNew__editIcon {
  cursor: pointer;
  color: #8c939f;
}
.c-ConditionNew__routeName {
  font-weight: 600;
  font-style: 15px;
}
.c-ConditionNew__routeName--default {
  color: #8c939f;
}
.c-ConditionNew__routeName .ant-tag {
  margin: 0;
}
.c-ConditionNew__arrowIcon {
  color: #8c939f;
  cursor: pointer;
}
.c-ConditionNew__routeNameInput {
  width: 100%;
}
.c-ConditionNew__shiftIcon {
  font-size: 22px;
  opacity: 0.5;
}
.c-ConditionNew__shiftIcon:hover {
  opacity: 0.8;
}
.c-ConditionNew__addGroupButton {
  background-color: #0958d9 !important;
  padding: 3px 10px !important;
  grid-gap: 3px !important;
}
.c-ConditionNew__addGroupButton span {
  font-size: 12px;
}
.c-ConditionNew__addRuleButton {
  background-color: #3a9c32 !important;
  padding: 3px 10px !important;
  grid-gap: 3px !important;
}
.c-ConditionNew__addRuleButton span {
  font-size: 12px;
}
.ant-alert-message .no-domains-alert {
  display: flex;
  align-items: center;
}
.ant-alert-message .no-domains-alert-icon {
  margin-right: 15px;
}

.add-page-note .ant-alert-message {
  margin-bottom: 0 !important;
}
.add-page-note .ant-alert-description {
  text-align: justify;
}

.ant-alert.funnel-note-alert {
  margin-bottom: 20px;
  padding: 8px 15px 8px 54px;
}
.ant-alert.funnel-note-alert .ant-alert-message {
  margin-bottom: 0;
}
.ant-alert.funnel-note-alert .anticon {
  left: 19px;
  top: 10px;
  font-size: 20px;
}

.unsaved-funnel-alert .ant-alert-warning {
  padding: 12px 15px 12px 37px;
}
.unsaved-funnel-alert .ant-alert-message {
  margin-bottom: 0 !important;
}
.unsaved-funnel-alert .ant-alert-with-description .ant-alert-icon {
  left: 10px;
  top: 12px;
  font-size: 24px;
  color: #faad14;
}
.unsaved-funnel-alert .ant-alert-with-description .ant-alert-icon svg {
  width: 14px;
}
.form-TrafficSource {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.form-TrafficSource .margin-bottom-0 {
  margin-bottom: 0;
}
.form-TrafficSource .ant-tag {
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  margin: 0;
}
.form-TrafficSource__customEventsHeaderText {
  font-weight: bold;
}
.form-TrafficSource__nameCol {
  color: #5488E2;
}
.form-TrafficSource__urlFields {
  margin-top: 10px;
}
.form-TrafficSource__urlFieldsHeader {
  color: #5d6d88 !important;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.form-TrafficSource__urlFieldsHeader--name {
  text-align: right;
  width: 120px;
  padding-right: 10px;
}
.form-TrafficSource__urlFieldsHeader--append {
  width: 60px;
}
.form-TrafficSource__urlFieldsName {
  width: 120px;
  text-align: right;
  padding-right: 10px;
  color: #5186e1;
}
.form-TrafficSource__urlFieldsName--disabled {
  color: rgba(0, 0, 0, 0.25);
}
.form-TrafficSource__urlFieldsInternalToken input {
  color: #5186e1;
}
.form-TrafficSource__urlFieldsAppend {
  width: 60px;
}
.form-TrafficSource__urlFieldsAppend .c-ffSwitch {
  margin: 0 auto;
}
.form-TrafficSource__conversionsTracking--tokensInserter .c-ffSelect input {
  width: 300px;
}
.form-TrafficSource--inputGroup {
  display: flex;
  flex-direction: column;
}
.form-TrafficSource--inputGroup.id {
  max-width: 100px;
}
.form-TrafficSource--inputGroup--name {
  flex: 1 1;
}
.form-TrafficSource--inputGroup--category {
  flex: 1 1;
  margin-right: 10px !important;
}
.form-TrafficSource--inputGroup--category--selectBox {
  align-items: center;
}
.form-TrafficSource--inputGroup--template {
  width: 112px;
  margin-top: -20px;
  background-color: #e6f3ff;
  border: 10px solid #e6f3ff;
  border-radius: 5px;
}
.form-TrafficSource--inputGroup--costType {
  flex: 1 1;
  max-width: 100px;
}
.form-TrafficSource--inputGroup--costPerEnt {
  flex: 1 1;
}
.form-TrafficSource--inputGroup--costPerEnt .ant-input-affix-wrapper {
  max-width: 170px;
}
.form-TrafficSource--inputGroup--costPerEnt--note {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.form-TrafficSource--inputGroup--trackingType {
  flex: 1 1;
  max-width: 140px;
  margin-right: 15px;
}
.form-TrafficSource--inputGroup--customScenarioType {
  width: 225px;
}
.form-TrafficSource--inputGroup--postbackURL {
  margin-top: 15px;
}
.form-TrafficSource--inputGroup--html {
  margin-top: 15px;
}
.form-TrafficSource--inputGroup--html .flex {
  margin-bottom: 10px;
}
.form-TrafficSource__helpSection h3 {
  color: #5d6d88;
  font-size: 16px;
  font-weight: 700;
}
.form-TrafficSource__helpSection strong {
  color: #5d6d88;
}
.form-TrafficSource__helpSection ul {
  margin-left: 30px;
}
.form-TrafficSource__helpSection ul ::marker {
  color: #5d6d88;
}
.form-TrafficSource__helpSection li {
  margin-bottom: 10px;
}

.restore-alert {
  display: flex;
  margin-bottom: 30px;
}
.restore-alert-icon {
  margin-right: 15px;
}
.c-customScenario {
  display: flex;
  flex-direction: column;
}
.c-customScenario__inputGroup {
  display: flex;
  flex: 1 1;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  max-width: 416px;
}
.c-customScenario__inputGroup label {
  margin-right: 10px;
  flex: 1 1;
  min-width: -webkit-max-content;
  min-width: max-content;
  display: block;
}
.c-customScenario__switch {
  margin-bottom: 10px;
}
.c-customScenario .switch-wrapper {
  margin-bottom: 10px;
  margin-left: 0px;
}
.c-conversionTracking__customEventType {
  min-width: 200px;
}
.c-conversionTracking__customScenario {
  margin-top: 15px;
}
.cform-landerOfferGroup--name {
  margin-bottom: 15px;
}
.cform-landerOfferGroup--name--offerLabel, .cform-landerOfferGroup--name--landerLabel {
  color: #404d66;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
  display: block;
}
.cform-landerOfferGroup--selectPage {
  margin-bottom: 15px;
}
.cform-landerOfferGroup--selectPage--offerLabel, .cform-landerOfferGroup--selectPage--landerLabel {
  color: #404d66;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
  display: block;
}
.cform-landerOfferGroup--selectPage--offerLabel i, .cform-landerOfferGroup--selectPage--landerLabel i {
  font-size: 32px;
  margin-right: 7px;
  vertical-align: -10px;
}
.cform-landerOfferGroup--selectPage--offerLabel svg {
  fill: #7ac243;
}
.cform-landerOfferGroup--selectPage--landerLabel svg {
  fill: #51a2ec;
}
.cform-landerOfferGroup--mapIncoming {
  margin-bottom: 15px;
}
.cform-landerOfferGroup--mapIncoming i {
  margin-left: 5px;
}
.cform-landerOfferGroup--buttons {
  margin-bottom: 15px;
}
.cform-landerOfferGroup--buttons button {
  color: #e9455e !important;
  border: 2px solid #e9455e !important;
}
.cform-landerOfferGroup--buttons button:hover {
  background: #e9455e !important;
  color: #fff !important;
}
.cform-landerOfferGroup--pages {
  border-top: 1px solid #e1e6eb;
}
.cform-landerOfferGroup--pageBox {
  padding: 20px 30px;
  border-bottom: 1px solid #e1e6eb;
  position: relative;
}
.cform-landerOfferGroup--pageBox.status-deleted .cform-landerOfferGroup--pageBox--name {
  font-style: italic;
  color: #ccc;
}
.cform-landerOfferGroup--pageBox.status-deleted .cform-landerOfferGroup--pageBox .flux-icon-modify {
  cursor: not-allowed;
}
.cform-landerOfferGroup--pageBox i {
  font-size: 20px;
}
.cform-landerOfferGroup--pageBox--dragActive {
  border-top: 1px solid #da365d;
}
.cform-landerOfferGroup--pageBox--dragElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
}
.cform-landerOfferGroup--pageBox--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cform-landerOfferGroup--pageBox--header .flux-icon-threedot {
  position: absolute;
  top: -10px;
  left: 51%;
  margin-left: -10px;
  -webkit-transform-origin: left;
          transform-origin: left;
  width: 20px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.cform-landerOfferGroup--pageBox--modifySwapDelete {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.cform-landerOfferGroup--pageBox--counter {
  font-size: 14px;
  font-weight: 400;
  color: #1890ff;
}
.cform-landerOfferGroup--pageBox--name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 15px;
  color: #404d66;
  padding-left: 26px;
  position: relative;
  white-space: nowrap;
}
.cform-landerOfferGroup--pageBox--name:before {
  position: absolute;
  content: " ";
  background-size: 23px 23px;
  background-position: left -2px center;
  left: -1px;
  top: -1px;
  width: 23px;
  height: 23px;
  -webkit-mask-size: 23px;
          mask-size: 23px;
}
.cform-landerOfferGroup--pageBox--icon-landerGroup:before {
  -webkit-mask-image: url(/static/media/Lander.99f47819.svg);
          mask-image: url(/static/media/Lander.99f47819.svg);
  background-color: rgb(24, 144, 255);
}
.cform-landerOfferGroup--pageBox--icon-landerGroup.status-deleted:before {
  background-color: #ccc;
}
.cform-landerOfferGroup--pageBox--icon-offerGroup:before {
  -webkit-mask-image: url(/static/media/Offer.44ab0599.svg);
          mask-image: url(/static/media/Offer.44ab0599.svg);
  background-color: rgb(136, 200, 118);
}
.cform-landerOfferGroup--pageBox--icon-offerGroup.status-deleted:before {
  background-color: #ccc;
}
.cform-landerOfferGroup--pageBox--body {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.cform-landerOfferGroup--pageBox--body--slider {
  flex: 1 1;
}
.cform-landerOfferGroup--pageBox--body--weightValue {
  font-size: 13px;
  color: #393939;
  font-weight: 600;
  min-width: 45px;
  margin-right: 20px;
}
.cform-landerOfferGroup--pageBox--body--weightInput {
  max-width: 60px;
}
.cform-landerOfferGroup--additionalSettings--item {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.cform-landerOfferGroup__javascriptTrackingTitle {
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  font-size: 18px;
}
.cform-landerOfferGroup__javascriptTrackingGlobalSection, .cform-landerOfferGroup__javascriptTrackingDefaultParamsSection {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.cform-landerOfferGroup__javascriptTrackingGlobalSection > p, .cform-landerOfferGroup__javascriptTrackingDefaultParamsSection > p {
  margin: 0;
}
.cform-getActionLink__box {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  overflow: hidden;
  margin: 0;
}
.cform-getActionLink__inputCopy {
  display: flex;
}
.c-entitySlider__root {
  border-top: 1px solid #e1e6eb;
}
.c-entitySlider__root .flux-icon {
  font-size: 20px;
}
.c-entitySlider__disabled {
  -webkit-filter: grayscale(90%);
          filter: grayscale(90%);
  cursor: not-allowed;
  pointer-events: none;
}
.c-entitySlider__dragElement {
  width: 100%;
  height: 20px;
  position: relative;
}
.c-entitySlider__dotIcon {
  position: absolute;
  top: -10px;
  left: 51%;
  margin-left: -10px;
  -webkit-transform-origin: left;
          transform-origin: left;
  width: 20px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.c-entitySlider__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-entitySlider__counter {
  font-size: 14px;
  font-weight: 400;
  color: #1890ff;
}
.c-entitySlider__nameIcon {
  max-width: 450px;
  font-weight: 600;
  font-size: 15px;
  color: #404d66;
  position: relative;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.c-entitySlider__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
}
.c-entitySlider__icon--landerGroup i {
  color: rgb(24, 144, 255);
}
.c-entitySlider__icon--offerGroup i {
  color: rgb(136, 200, 118);
}
.c-entitySlider__status--deleted {
  color: #ccc;
  font-style: italic;
}
.c-entitySlider__status--deleted.c-entitySlider__nameIcon i {
  color: #ccc;
}
.c-entitySlider__modifySwapDelete {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.c-entitySlider__body {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.c-entitySlider__slider {
  width: 100%;
  margin-right: 16px !important;
}
.c-entitySlider__weightInput {
  max-width: 70px;
}
.c-entitySlider__weightValue {
  font-size: 13px;
  color: #393939;
  font-weight: 600;
  min-width: 45px;
  margin-right: 20px;
}
.c-pageGroupPageSlider--pageBox--dragActive {
  border-top: 1px solid #da365d;
}
.cform-funnelGroup {
  display: flex;
  flex-direction: column;
}
.cform-funnelGroup--nameIdRow {
  margin-bottom: 15px;
}
.cform-funnelGroup--name {
  flex: 1 1;
}
.cform-funnel--funnelGroup .flux-icon-addnew {
  align-self: flex-end;
  margin: 0px 0px 5px 15px;
}
.cform-funnel--horizontalArrow {
  align-self: flex-end;
  margin-bottom: 8px;
}
.cform-funnel--defaultCost .flux-icon-information {
  margin-left: 5px;
}
.cform-funnel--costOverrideChild {
  align-items: center;
}
.cform-funnel--costOverrideChild--body {
  flex: 1 1;
}
.cform-funnel__codeSnippetTitle {
  font-family: "Open Sans";
  color: #5d6d88;
  font-weight: 600;
  font-size: 20px;
}
.state-renderer .step {
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #108ee9;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}
.sb-funnel__titleIconWrapper {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.sb-funnel__title {
  font-size: 22px;
  font-weight: 600;
  color: #5d6d88;
}
.sb-funnel__link {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  border-left: 1px solid #e9e9e9;
  padding-left: 20px;
}
.sb-funnel__linkText {
  color: #1890ff;
  font-size: 22px;
  font-weight: 600;
}
.cform-simpleFlows__configurePaths--pathsWrapper {
  display: flex;
  flex-direction: column;
}
.cform-simpleFlows__configurePaths--path {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
  padding: 15px 20px;
}
.cform-simpleFlows__configureRules--rulesWrapper {
  display: flex;
  flex-direction: column;
}
.cform-simpleFlows__configureRules--rule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
  padding: 15px 20px;
}
.cform-simpleFlows__pathEditor {
  margin-bottom: 150px;
}
.cform-simpleFlows__pathEditor--group {
  margin-bottom: 0;
}
.cform-simpleFlows__advancedSettings--costOverrideChild {
  align-items: center;
}
.cform-simpleFlows__advancedSettings--costOverrideChild--body {
  flex: 1 1;
}
.cform-simpleFlows__advancedSettings--costOverrideChildSelect {
  width: 100%;
}
.c-conditionRule__slider--body {
  padding: 11px;
  width: 100%;
  border-radius: 4px;
}
.c-conditionRule__slider--operator {
  position: relative;
}
.c-conditionRule__slider--operator::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 15px;
  background: #0089ff;
  width: 11px;
  height: 4px;
}
.c-conditionRule__slider--bodyHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.c-conditionRule__slider--bodyGroups {
  position: relative;
}
.c-conditionRule__slider--bodyGroup {
  border: 1px solid #c2d2d9;
  background-color: #fff;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
}
.c-conditionRule__slider--bodyGroupLine {
  visibility: hidden;
}
.c-conditionRule__slider--bodyGroupWithLine::before {
  content: "";
  visibility: visible;
  position: absolute;
  left: -10px;
  bottom: 15px;
  background: #0089ff;
  width: 11px;
  height: 4px;
}
.c-conditionRule__slider--bodyGroupWithLine .c-conditionRule__slider--bodyGroupLine {
  content: "";
  visibility: visible;
  position: absolute;
  left: -10px;
  top: -30px;
  background: #0089ff;
  width: 4px;
  height: calc(100% + 12px);
}
.c-conditionRule__slider--bodyGroupOperator {
  position: relative;
}
.c-conditionRule__slider--bodyGroupOperator::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 14px;
  background: #fda74e;
  width: 8px;
  height: 2px;
}
.c-conditionRule__slider--shapeIcon__icon {
  font-size: 10px;
}
.c-conditionRule__slider--deleteIcon {
  font-size: 18px;
}
.c-conditionRule__slider--bodyGroupHead {
  display: flex;
  align-items: center;
  position: relative;
}
.c-conditionRule__slider--bodyGroupRule {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-conditionRule__slider--bodyGroupRuleFields {
  display: flex;
  flex: 1 1;
  margin: 10px 10px 0px 0px;
  position: relative;
  grid-gap: 5px;
  gap: 5px;
}
.c-conditionRule__slider--bodyGroupRuleFields .ant-select {
  min-width: 115px;
  width: auto;
}
.c-conditionRule__slider--bodyGroupRuleFields .ant-select:last-child {
  flex: 1 1;
}
.c-conditionRule__slider--bodyGroupRuleFields .ant-select:first-child {
  max-width: 115px;
}
.c-conditionRule__slider--bodyGroupRuleFieldsWithLine::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 15px;
  background: #fda74e;
  width: 9px;
  height: 2px;
}
.c-conditionRule__slider--bodyGroupRuleFieldsWithLine .c-conditionRule__slider--bodyGroupRuleFieldsLeftLine {
  content: "";
  visibility: visible;
  position: absolute;
  left: -8px;
  top: -30px;
  background: #fda74e;
  width: 2.5px;
  height: calc(100% + 12px);
}
.c-conditionRule__slider--bodyGroupRuleFieldsLeftLine {
  visibility: hidden;
}
.c-resetDataDeleteForm__text {
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  color: #4b606d;
}
.c-resetDataDeleteForm__redText {
  color: #ec4f69;
  font-weight: bold;
  margin-right: 5px;
}
.sb-funnelFlowCampaign {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}
.sb-funnelFlowCampaign__box {
  display: flex;
  justify-content: space-between;
}
.sb-funnelFlowCampaign__button {
  width: 200px;
}
.sb-funnelFlowCampaign__text {
  font-weight: 500;
  font-size: 15px;
}
.sb-funnelFlowCampaign__folderIcon {
  color: #686868;
}
.sb-funnelFlowCampaign__flowPathIcon {
  color: #63707e;
}
.sb-funnelFlowCampaign__funnelCreateIcon {
  color: #63707e;
}
.sb-landerOfferGroup {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}
.sb-landerOfferGroup__box {
  display: flex;
  justify-content: space-between;
}
.sb-landerOfferGroup__button {
  width: 200px;
}
.sb-landerOfferGroup__text {
  font-weight: 500;
  font-size: 15px;
}
.sb-landerOfferGroup__offerIcon {
  color: #7ac243;
}
.sb-landerOfferGroup__landerIcon {
  color: #1890ff;
}
.c-quickstatsSidebar {
  height: 100%;
}
.c-quickstatsSidebar .container {
  padding-right: 5px !important;
}
.c-fluxLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 40;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  position: absolute;
}
.c-fluxLoading__loader {
  margin: auto;
  color: #1890ff !important;
  font-size: 30px;
}

.c-centerLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-centerLoading__loader {
  margin: auto;
  color: #1890ff !important;
  font-size: 30px;
}
.c-categoryForm {
  margin-bottom: 25px;
}
.c-categoryForm__addRemoveGroupChild {
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}
.c-categoryForm__addRemoveGroupChild--body {
  flex: 1 1;
}
.c-sidebarTrigger {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  color: #1890ff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.c-sidebarTrigger:hover {
  color: #3c42ff;
}
/* LAYOUT_SIZES */
/* LAYOUT_TYPOGRAPHY */
.flux-layout {
  height: auto;
  min-height: 100vh;
  background: #fff;
}
.flux-layout.subscriptionCancelled {
  min-height: calc(100vh - 38px);
}
.flux-layout .ant-layout-sider {
  height: auto;
  min-height: 100%;
  z-index: 1;
}
.flux-layout .ant-layout-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: rgb(247, 247, 249);
  overflow-y: hidden;
}
.flux-layout .ant-layout-content .container {
  padding: 0 20px;
  height: 100%;
  overflow: hidden;
}
.flux-layout .ant-layout-content .overlay-wrapper {
  box-shadow: none !important;
  height: calc(100% - 50px);
  width: auto;
  left: 60px;
  top: 5px;
}
.flux-layout .ant-layout-content .overlay-wrapper .flux-buttons--filter {
  margin-right: 30px;
}
.flux-layout .ant-layout-content .overlay-wrapper .overlay-close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
}
.flux-layout .ant-layout-content .overlay-wrapper .overlay-close-icon i,
.flux-layout .ant-layout-content .overlay-wrapper .overlay-close-icon svg {
  width: 100%;
  height: 100%;
  margin-right: 0 !important;
}
.flux-layout .ant-layout-content .ag-theme-balham .partial-height {
  position: relative;
}
.flux-layout .ant-layout-content .ag-theme-balham .partial-height .ag-floating-bottom {
  height: 39px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
}
.flux-layout .ant-layout-content .ag-theme-balham .partial-height .ag-floating-bottom .ag-floating-bottom-container .ag-row {
  font-weight: bold;
  background-color: #f5f8fa;
  height: 36px !important;
  border-radius: 0px 0px 5px 5px;
}
.flux-layout .ant-layout-content .ag-theme-balham .partial-height .ag-floating-bottom .ag-floating-bottom-container [col-id=ag-Grid-AutoColumn] {
  border-right: 0;
}
.flux-layout .ant-layout-content .ag-theme-balham .partial-height .ag-floating-bottom .ag-floating-bottom-container *:before {
  display: none;
}
.flux-layout .ant-layout-content .autoHeight-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flux-layout .ant-layout-content .autoHeight-table-container {
  height: 100%;
  min-height: 240px;
  position: relative;
}
.flux-layout .ant-layout-content .autoHeight-table-container .ag-theme-balham {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 40px);
}
.flux-layout .ant-layout-content .autoHeight-table-container .ag-theme-balham .ag-theme-balham {
  display: block;
  position: initial;
  min-height: 1px;
  height: 1px;
}

.ant-modal .flux-loading {
  background: rgba(255, 255, 255, 0.5) !important;
  margin-top: 57px;
  height: calc(100% - 57px) !important;
}

.flux-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  font-family: "Open Sans", sans-serif;
}
.flux-buttons.content-start {
  justify-content: start;
}
.flux-buttons.align-center {
  align-items: center;
  align-self: center;
}
.flux-buttons.align-center .ff-buttons {
  align-self: center;
}
.flux-buttons.flux-buttons-conditions {
  height: 63px;
}
.flux-buttons > div {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .flux-buttons--crud div .ff-button-switch {
    margin-right: 9px;
  }
  .flux-buttons--crud div:first-child {
    margin-right: 8px;
  }
  .flux-buttons--crud div:last-child {
    margin-left: 8px;
  }
}
.flux-buttons--crud .name {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #404d66;
}
.flux-buttons--crud > i {
  font-size: 24px;
  margin-right: 20px;
  color: #1890ff;
  cursor: pointer;
}
.flux-buttons--crud .edit, .flux-buttons--crud .modal-trigger, .flux-buttons--crud .sidebar-trigger {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4b606d;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.flux-buttons--crud .edit button, .flux-buttons--crud .modal-trigger button, .flux-buttons--crud .sidebar-trigger button {
  margin-right: 9px;
}
.flux-buttons--crud .modal-trigger, .flux-buttons--crud .sidebar-trigger {
  cursor: pointer;
  margin-right: 15px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #1890ff;
  position: relative;
}
.flux-buttons--crud .modal-trigger .visible, .flux-buttons--crud .sidebar-trigger .visible {
  position: absolute;
  left: 33px;
}
.flux-buttons--crud .modal-trigger .hidden, .flux-buttons--crud .sidebar-trigger .hidden {
  visibility: hidden;
}
.flux-buttons--crud .modal-trigger i, .flux-buttons--crud .sidebar-trigger i {
  margin-right: 9px;
}
.flux-buttons--filter > * {
  margin-left: 20px;
}
@media screen and (max-width: 1280px) {
  .flux-buttons--filter > * {
    margin-left: 8px;
  }
}
.flux-buttons--filter .filter-counter {
  margin-left: 10px;
  color: #1890ff;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
.flux-buttons--filter .switch-wrapper {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #89959c;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.flux-buttons--filter .switch-wrapper .flux-icon-information {
  margin-left: 10px;
}
.flux-buttons--filter .switch-wrapper button {
  margin-right: 9px;
}

form {
  font-family: "Open Sans", sans-serif;
}
form button {
  width: 100%;
  font-family: "Open Sans", sans-serif;
}
form.flux-base-form {
  width: 269px;
  padding: 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 12px;
}
form.flux-base-form .flux-input-group {
  margin-bottom: 12px;
}

.flux-table {
  background: #fff;
}

.flux-icon-tableQuickstats, .flux-icon-tableEdit, .flux-icon-tableArchive, .flux-icon-tableUnArchive, .flux-icon-tableDelete, .flux-icon-tableCopy, .flux-icon-tableOpenBuilder {
  display: inline-flex;
  font-size: 16px;
  cursor: pointer;
  color: #8d9aa8;
}
.flux-icon-tableOpenBuilder:hover svg #funnel_editor {
  fill: #ffffff;
}
.flux-icon-tableQuickstats svg {
  height: 32px;
  width: 32px;
  opacity: 0.8;
}
.flux-icon-tableQuickstats svg:hover {
  opacity: 1;
  -webkit-filter: brightness(10);
          filter: brightness(10);
}
.flux-icon-tableEdit svg {
  height: 16px;
  width: 16px;
}
.flux-icon-tableArchive svg {
  height: 16px;
  width: 21px;
}

.container {
  padding: 0 15px;
  height: 100%;
  overflow: hidden;
}

.ag-theme-balham .partial-height {
  position: relative;
}
.ag-theme-balham .partial-height .ag-floating-bottom {
  height: 39px;
  width: 100%;
}
.ag-theme-balham .partial-height .ag-floating-bottom .ag-floating-bottom-container .ag-row {
  font-weight: bold;
  background-color: #f5f8fa;
  height: 36px !important;
}
.ag-theme-balham .partial-height .ag-floating-bottom .ag-floating-bottom-container [col-id=ag-Grid-AutoColumn] {
  border-right: 0;
}
.ag-theme-balham .partial-height .ag-floating-bottom .ag-floating-bottom-container *:before {
  display: none;
}

.autoHeight-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.autoHeight-table-container {
  height: 100%;
  min-height: 240px;
  position: relative;
}
.autoHeight-table-container .ag-theme-balham {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 40px);
}
.autoHeight-table-container .ag-theme-balham .ag-theme-balham {
  display: block;
  position: initial;
  min-height: 1px;
  height: 1px;
}
.autoHeight-table-container .ag-theme-balham .partial-height {
  position: absolute;
  top: 0;
  height: calc(100% - 50px) !important;
  width: 100%;
}

.table-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.border-1 {
  border: 1px solid #e1e6eb;
}
.border-1-onHover:hover {
  border: 1px solid #e1e6eb;
}
.border-bottom-1 {
  border-bottom: 1px solid #e1e6eb;
}
.border-top-1 {
  border-top: 1px solid #e1e6eb;
}
.border-right-1 {
  border-right: 1px solid #e1e6eb;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-top-left-0 {
  border-top-left-radius: 0 !important;
}
.border-radius-top-right-0 {
  border-top-right-radius: 0 !important;
}
.border-radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}
.border-radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.position-relative {
  position: relative;
}

.margin-0 {
  margin: 0px;
}
.margin-x-5 {
  margin-right: 5px;
  margin-left: 5px;
}
.margin-x-15 {
  margin-right: 15px;
  margin-left: 15px;
}
.margin-x-auto {
  margin-right: auto;
  margin-left: auto;
}
.margin-y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.margin-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-20-important {
  margin-bottom: 20px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-5-important {
  margin-bottom: 5px !important;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-left-auto {
  margin-left: auto;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-auto {
  margin-right: auto;
}
.margin-auto {
  margin: auto;
}

.display-block {
  display: block;
}

.padding-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.padding-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.padding-y-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.padding-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-y-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-25 {
  padding-left: 25px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-top-10 {
  padding-top: 10px;
}

.width-maxContent {
  width: -webkit-max-content;
  width: max-content;
}
.width-minContent {
  width: -webkit-min-content;
  width: min-content;
}
.width-fitContent {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.width-auto {
  width: auto;
}
.width-full {
  width: 100%;
}
.width-134 {
  width: 134px;
}
.width-115 {
  width: 115px;
}
.width-190 {
  width: 190px;
}
.width-174 {
  width: 174px;
}
.width-320 {
  width: 320px;
}

.white-space-unset {
  white-space: unset;
}

.overlay-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.text-overflow-ellipse {
  text-overflow: ellipsis;
}

.height-full {
  height: 100%;
}
.height-35 {
  height: 35px;
}
.height-40 {
  height: 40px;
}
.height-75 {
  height: 75px;
}
.height-1 {
  height: 1px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}

.min-width-fitContent {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.min-height-70 {
  min-height: 70px;
}
.min-height-full {
  height: 100%;
}

.max-width-134 {
  max-width: 134px;
}
.max-width-397 {
  max-width: 397px;
}
.max-width-450 {
  max-width: 450px;
}
.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}

.line-height-1-86 {
  line-height: 1.86;
}

.icon-size-22 {
  font-size: 22px;
}

.font-size-13 {
  font-size: 13px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-25 {
  font-size: 25px;
}
.font-size-26 {
  font-size: 26px;
}

.font-weight-700 {
  font-weight: 700;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-bold {
  font-weight: bold;
}

.bgcolor-f5f5f5 {
  background-color: #f5f5f5;
}
.bgcolor-fff {
  background-color: #fff;
}
.bgcolor-hover-eee:hover {
  background-color: #eee;
}

.color-grayTitle {
  color: #404d66;
}
.color-4b606d {
  color: #4b606d;
}
.color-5e646f {
  color: #5e646f;
}
.color-7786a1 {
  color: #7786a1;
}
.color-465565 {
  color: #465565;
}
.color-5a6a7b {
  color: #5a6a7b;
}
.color-657686 {
  color: #657686;
}
.color-red {
  color: #e9455e;
}
.color-blue {
  color: #1890ff;
}
.color-hover-white:hover {
  color: #fff;
}

.flex {
  display: flex;
}
.flex-wrap-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1 1;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-align-center {
  align-items: center;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-end {
  justify-content: end;
}
.flex-justify-start {
  justify-content: start;
}
.flex-justify-space-around {
  justify-content: space-around;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.flex-gap-30 {
  grid-gap: 30px;
  gap: 30px;
}
.flex-gap-25 {
  grid-gap: 25px;
  gap: 25px;
}
.flex-gap-20 {
  grid-gap: 20px;
  gap: 20px;
}
.flex-gap-15 {
  grid-gap: 15px;
  gap: 15px;
}
.flex-gap-10 {
  grid-gap: 10px;
  gap: 10px;
}
.flex-gap-5 {
  grid-gap: 5px;
  gap: 5px;
}
.flex-flow-wrap {
  flex-flow: wrap;
}
.flex-align-self-end {
  align-self: flex-end;
}
.flex-none {
  flex: none;
}

.text-align-left {
  text-align: left;
}

.util-select-with-arrow.ant-select-focused:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.util-select-with-arrow:before {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  right: 11px;
  top: 12px;
  content: " ";
  background: url(/static/media/anticon-down.ba772b14.svg);
  width: 11px;
  height: 12px;
  color: gray;
  background-size: 11px 12px;
}
.util-select-height-35 .ant-select-selection {
  height: 35px !important;
}
.util-js-textarea {
  overflow-y: auto;
  font-family: "Fira Mono";
  font-size: 12px !important;
  font-weight: 400;
  color: #ffffff;
  background-color: #667787;
}
.util-readOnly-input {
  background-color: #f5f5f5;
}
.util-readOnly-input input {
  background-color: transparent;
}
.util-input-blueText input {
  color: #1890ff;
}
.util-blue-button-active {
  border-color: #1890ff;
  background: #1890ff;
  color: #fff !important;
}

@font-face {
  font-family: SFMono;
  font-weight: 500;
  src: url(/static/media/SFMonoMedium.001ca8e7.ttf) format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  font-weight: 400;
  color: #1f2f3d;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.loading-wrapper {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-wrapper .logo {
  width: 250px;
  height: 250px;
  opacity: 0.1;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  background: url(/static/media/logo.01158979.svg);
  background-size: 250px 250px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.loading-wrapper .info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading-wrapper .info > button {
  padding: 10px 50px;
  background: linear-gradient(224.5deg, #ef4a32 0%, #ec4990 100%);
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
}

.table-column-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  z-index: 50;
  border-radius: 2px;
  color: #fff;
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.animated {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.04;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }
  to {
    opacity: 1;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.04;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }
  to {
    opacity: 1;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
  }
}
.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0.12;
  font-weight: bold;
}
.not-found .four-0-four {
  display: flex;
  justify-content: space-evenly;
  font-size: 60px;
  text-align: center;
  line-height: 60px;
}
.not-found h1 {
  font-size: 50px;
  line-height: 60px;
  display: block;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.ant-tooltip.side-item-tooltip {
  left: 52px !important;
}
.ant-tooltip.side-item-tooltip, .ant-tooltip.editmode-button-tooltip {
  max-width: 179px;
  -webkit-user-select: none;
          user-select: none;
  height: 44px;
  overflow: hidden;
  padding-left: 4px;
}
.ant-tooltip.side-item-tooltip:first-of-type, .ant-tooltip.editmode-button-tooltip:first-of-type {
  top: -44px;
}
.ant-tooltip.side-item-tooltip:before, .ant-tooltip.editmode-button-tooltip:before {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 0;
  height: 8px;
  width: 5px;
  background: url(/static/media/tooltip-bg.7cdb23e7.svg) left no-repeat;
  content: " ";
}
.ant-tooltip.side-item-tooltip .ant-tooltip-content, .ant-tooltip.editmode-button-tooltip .ant-tooltip-content {
  background: white;
  border: 1px solid #e1e6eb;
  border-radius: 3px;
  height: 44px;
}
.ant-tooltip.side-item-tooltip .ant-tooltip-content > .ant-tooltip-arrow, .ant-tooltip.editmode-button-tooltip .ant-tooltip-content > .ant-tooltip-arrow {
  display: none;
}
.ant-tooltip.side-item-tooltip .ant-tooltip-content > .ant-tooltip-inner, .ant-tooltip.editmode-button-tooltip .ant-tooltip-content > .ant-tooltip-inner {
  padding: 0;
  font-size: 13px;
  background: none;
  height: 42px;
  line-height: 42px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}
.ant-tooltip.side-item-tooltip .ant-tooltip-content > .ant-tooltip-inner span, .ant-tooltip.editmode-button-tooltip .ant-tooltip-content > .ant-tooltip-inner span {
  margin: 0;
  padding: 0 15px 0 20px;
  margin-left: -5px;
  font-size: 13px;
  color: #4c4c4c;
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  position: relative;
  z-index: 100;
}
.ant-tooltip.popup-dialog-tooltip {
  bottom: auto !important;
}
.ant-tooltip.popup-dialog-tooltip .ant-tooltip-content {
  background: none;
}
.ant-tooltip.popup-dialog-tooltip .ant-tooltip-content * {
  -webkit-user-select: none;
          user-select: none;
}
.ant-tooltip.popup-dialog-tooltip .ant-tooltip-content > .ant-tooltip-arrow {
  display: none;
}
.ant-tooltip.popup-dialog-tooltip .ant-tooltip-content > .ant-tooltip-inner {
  box-shadow: none;
  padding: 0;
  background: none;
  font-size: 14px;
}
.ant-tooltip.popup-dialog-tooltip {
  padding: 0;
  width: 100%;
}
.ant-tooltip.popup-dialog-tooltip .ant-tooltip-content {
  padding: 0 0 0 8px;
  right: 90px !important;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #e7edf3;
  border: solid 1px #e1e6eb;
  margin-left: -9px;
}
.ant-tooltip.popup-dialog-tooltip i {
  color: #737d87;
}
.ant-tooltip.popup-dialog-tooltip i svg {
  width: 21px;
}
.ant-tooltip.popup-dialog-tooltip i.flux-icon-giveFeedback svg {
  fill: none;
  stroke: #737d87;
}
.ant-tooltip.popup-dialog-tooltip i.flux-icon-onboarding svg {
  fill: #737d87;
  height: 24px;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video {
  border-bottom: solid 1px #e1e6eb;
  margin-left: -8px;
  width: calc(100% + 8px);
  padding: 11px 0;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video:hover {
  background-color: #f5f8fa;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video:hover .video-name {
  color: #1890ff;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video:hover .video-duration {
  color: #525c67;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video p {
  width: 145px;
  margin-bottom: 0;
  margin-right: 16px;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video .video-preview {
  width: 65px;
  height: 40px;
  border-radius: 4px;
  background-color: #5f6d7b;
  margin-right: 16px;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video .video-name {
  font-weight: 600;
  color: #5e646f;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 4px;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video .video-duration {
  color: #8d9aa8;
  font-size: 12px;
  line-height: 17px;
}
.ant-tooltip.popup-dialog-tooltip .onboarding-video .video .flux-icon-close svg {
  width: 18px;
  height: 18px;
}
.ant-tooltip.popup-dialog-tooltip .ant-tooltip-inner {
  padding: 0 !important;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu {
  padding: 14px 0;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a {
  border: none;
  margin-left: -8px;
  width: calc(100% + 8px);
  padding: 0 22px;
  display: flex;
  align-items: center;
  color: #5e646f;
  line-height: 40px;
  cursor: pointer;
  outline: none;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button.unread,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a.unread {
  position: relative;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button.unread:after,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a.unread:after {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: #1890ff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 11px;
  top: calc(50% - 5px);
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button i,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a i {
  text-align: center;
  width: 20px;
  margin-right: 13px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-userSettings svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-userSettings svg {
  width: 15px;
  height: 17px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-billingSub svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-billingSub svg {
  width: 20px;
  height: 19px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-userManagement svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-userManagement svg {
  width: 18.6px;
  height: 24px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-logout svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-logout svg {
  width: 20px;
  height: 22px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-productOverview svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-productOverview svg {
  width: 20px;
  height: 21px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-productPortal svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-productPortal svg {
  width: 20px;
  height: 20px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-knowledgeBase svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-knowledgeBase svg {
  width: 20px;
  height: 21px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-contactSupport svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-contactSupport svg {
  width: 20px;
  height: 21px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button .flux-icon-productChangelog svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a .flux-icon-productChangelog svg {
  width: 20px;
  height: 20px;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button {
  background-color: transparent;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button:hover,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a:hover {
  background-color: #f5f8fa;
  color: #ef4a32;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button:hover .flux-icon-giveFeedback svg,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a:hover .flux-icon-giveFeedback svg {
  stroke: #ef4a32;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu button:hover i,
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu a:hover i {
  color: #ef4a32;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu .product-changelog-container {
  position: absolute;
  left: -213px;
  padding-right: 6px;
  top: 0;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu .product-changelog-container .product-changelog {
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #e7edf3;
  padding-left: 8px;
  width: 200px;
  background: #ffffff;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu .product-changelog-container .product-changelog .unread {
  position: relative;
}
.ant-tooltip.popup-dialog-tooltip .popup-dialog-menu .product-changelog-container .product-changelog .unread:after {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: #1890ff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 11px;
  top: calc(50% - 5px);
}

.ant-message {
  z-index: 3000;
}
.ant-message-notice-content {
  max-width: 50%;
}

* {
  scrollbar-color: #229ee6 #eff4f8;
  scrollbar-width: thin !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  border-radius: 3.5px;
  background-color: #eff4f8;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  width: 4px;
  height: 4px;
  border-radius: 3.5px;
  background-color: #eff4f8;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 3.5px;
  background-color: #229ee6;
}

.ag-body-horizontal-scroll-viewport {
  min-width: 100% !important;
}

.ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: transparent !important;
}

.ant-modal .ant-select-multiple,
.dynamic-sidebar-body .ant-select-multiple,
.node-settings .ant-select-multiple {
  position: relative;
  height: 35px;
  padding: 0;
  margin: 0;
}
.ant-modal .ant-select-multiple .ant-select-selection__rendered,
.dynamic-sidebar-body .ant-select-multiple .ant-select-selection__rendered,
.node-settings .ant-select-multiple .ant-select-selection__rendered {
  top: 0;
  position: absolute;
  width: 100%;
  height: 33px;
  margin: 0;
}
.ant-modal .ant-select-multiple .ant-select-selection__rendered:after,
.dynamic-sidebar-body .ant-select-multiple .ant-select-selection__rendered:after,
.node-settings .ant-select-multiple .ant-select-selection__rendered:after {
  top: 0;
  position: absolute;
}
.ant-modal .ant-select-multiple .ant-select-selection__rendered .ant-select-selection__placeholder,
.dynamic-sidebar-body .ant-select-multiple .ant-select-selection__rendered .ant-select-selection__placeholder,
.node-settings .ant-select-multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  height: 33px;
  line-height: 33px;
  padding-left: 6px;
}
.ant-modal .ant-select-multiple .ant-select-selection__rendered ul,
.dynamic-sidebar-body .ant-select-multiple .ant-select-selection__rendered ul,
.node-settings .ant-select-multiple .ant-select-selection__rendered ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33px;
  overflow: hidden;
  padding: 5px 20px 5px 6px !important;
}
.ant-modal .ant-select-multiple .ant-select-selection__rendered ul li,
.dynamic-sidebar-body .ant-select-multiple .ant-select-selection__rendered ul li,
.node-settings .ant-select-multiple .ant-select-selection__rendered ul li {
  height: 24px;
  margin: 0 !important;
}
.ant-modal .ant-select-multiple .ant-select-selection__rendered ul li input:not([type=checkbox]),
.dynamic-sidebar-body .ant-select-multiple .ant-select-selection__rendered ul li input:not([type=checkbox]),
.node-settings .ant-select-multiple .ant-select-selection__rendered ul li input:not([type=checkbox]) {
  height: 24px;
}

.ant-modal {
  max-height: calc(100vh - 10px);
  padding: 0 !important;
  -webkit-transform-origin: 50% 50% !important;
          transform-origin: 50% 50% !important;
}
.ant-modal-content {
  max-height: 100%;
  border-radius: 10px;
  box-shadow: 0 0px 15px 2px rgba(0, 0, 0, 0.2);
}
.ant-modal-content .with-common-text-margin p {
  margin-bottom: 10px;
}
.ant-modal-body {
  max-height: calc(100vh - 75px);
  height: auto;
}
.ant-modal-body form {
  border-radius: 0 0 4px 4px;
  overflow: auto;
  max-height: calc(100vh - 80px);
  height: auto;
}
.ant-modal-header {
  padding: 18px 20px 14px 30px;
  border-bottom: 1px solid #e9e9e9;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404d66;
  border-radius: 10px 10px 0px 0px;
}
.ant-modal-title i.flux-icon-close:hover {
  color: rgba(126, 142, 173, 0.8) !important;
}
.ant-modal-title .title-link a {
  display: inline-block;
  margin-left: 20px;
  padding-left: 20px;
  border-left: solid 1px #e9e9e9;
  line-height: 24px;
}
.ant-modal-title .title-link svg {
  margin-right: 9px;
  fill: #1890ff;
}
.ant-modal-body {
  padding: 0;
}
.ant-modal-footer {
  text-align: left;
  border-top: none;
}

.flux-buttons--filter .traffic-source-select .ant-select-selection {
  max-height: 35px;
  overflow: hidden;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 13px;
}

.loading-indicator {
  position: relative;
  width: 35px;
  height: 35px;
  border: solid 5px transparent;
  display: flex;
  justify-content: center;
}
.loading-indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid #1890ff;
  border-radius: 50%;
  -webkit-animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1890ff transparent transparent transparent;
  z-index: 1;
}
.loading-indicator div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.loading-indicator div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.loading-indicator div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
.loading-indicator div:nth-child(4) {
  border: 4px solid #f1f4fa;
  z-index: 0;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
[aria-disabled=true] {
  cursor: not-allowed !important;
}
[aria-disabled=true] * {
  cursor: not-allowed !important;
  color: gray;
}

.ant-select-multiple .ant-select-search--inline {
  position: absolute;
}

.ant-select-open .ant-select-multiple .ant-select-search--inline {
  position: static;
}

._hidden {
  display: none !important;
}
.error-page {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-page span {
  margin: 0;
}
.error-page span img {
  width: 100px;
}
.error-page span .error-text {
  float: right;
  font-weight: 600;
  color: #5d6d88;
  font-size: 16px;
  margin-left: 30px;
  margin-top: 20px;
}
.cform-modifyAction__box {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  overflow: hidden;
  margin: 0;
}
.cform-modifyAction__inputCopy {
  display: flex;
}
.cform-modifyAction__eventTriggerHeading {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #e0e0e0;
  color: #5d6d88;
  font-size: 16px;
  font-weight: 700;
}
.create-or-open .c-contentWrapper {
  flex-direction: row !important;
}
.create-or-open .c-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.create-or-open .c-Button:hover i {
  color: #fff;
}
