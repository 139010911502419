// BASE-FORM
$base-form-width: 269px;
// LOGIN-FORM
$login-form-width: 346px;
$login-form-logo-height: 90px;
$login-form-logo-width: 80px;
$login-form-logo-margin: 0 0 40px 0;
$login-form-button-height: 45px;
$login-form-button-radius: 8px;
$login-form-btn-bg-1: linear-gradient(224.5deg, #ef4a32 0%, #ec4990 100%);
$login-form-btn-bg-2: linear-gradient(40.5deg, #ef4a32 0%, #ec4990 100%);
