.border {
  &-1 {
    border: 1px solid #e1e6eb;
    &-onHover {
      &:hover {
        border: 1px solid #e1e6eb;
      }
    }
  }
  &-bottom-1 {
    border-bottom: 1px solid #e1e6eb;
  }
  &-top-1 {
    border-top: 1px solid #e1e6eb;
  }
  &-right-1 {
    border-right: 1px solid #e1e6eb;
  }

  &-radius {
    &-6 {
      border-radius: 6px;
    }
    &-top {
      &-left-0 {
        border-top-left-radius: 0 !important;
      }
      &-right-0 {
        border-top-right-radius: 0 !important;
      }
    }
    &-bottom {
      &-left-0 {
        border-bottom-left-radius: 0;
      }
      &-right-0 {
        border-bottom-right-radius: 0;
      }
    }
  }
}

.position {
  &-relative {
    position: relative;
  }
}

.margin {
  &-0 {
    margin: 0px;
  }
  &-x {
    &-5 {
      margin-right: 5px;
      margin-left: 5px;
    }
    &-15 {
      margin-right: 15px;
      margin-left: 15px;
    }
    &-auto {
      margin-right: auto;
      margin-left: auto;
    }
  }
  &-y {
    &-15 {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &-25 {
      margin-top: 25px;
      margin-bottom: 25px;
    }
    &-auto {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  &-bottom {
    &-30 {
      margin-bottom: 30px;
    }
    &-20 {
      margin-bottom: 20px;
      &-important {
        margin-bottom: 20px !important;
      }
    }
    &-15 {
      margin-bottom: 15px;
    }
    &-10 {
      margin-bottom: 10px;
    }
    &-5 {
      margin-bottom: 5px;
      &-important {
        margin-bottom: 5px !important;
      }
    }
  }
  &-top {
    &-15 {
      margin-top: 15px;
    }
    &-10 {
      margin-top: 10px;
    }
    &-20 {
      margin-top: 20px;
    }
    &-5 {
      margin-top: 5px;
    }
  }
  &-left {
    &-15 {
      margin-left: 15px;
    }
    &-10 {
      margin-left: 10px;
    }
    &-25 {
      margin-left: 25px;
    }
    &-auto {
      margin-left: auto;
    }
  }
  &-right {
    &-15 {
      margin-right: 15px;
    }
    &-10 {
      margin-right: 10px;
    }
    &-auto {
      margin-right: auto;
    }
  }
  &-auto {
    margin: auto;
  }
}

.display {
  &-block {
    display: block;
  }
}

.padding {
  &-x {
    &-10 {
      padding-left: 10px;
      padding-right: 10px;
    }
    &-25 {
      padding-left: 25px;
      padding-right: 25px;
    }
    &-20 {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-y {
    &-10 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &-15 {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &-20 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-25 {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &-left {
    &-20 {
      padding-left: 20px;
    }
    &-25 {
      padding-left: 25px;
    }
  }
  &-bottom {
    &-20 {
      padding-bottom: 20px;
    }
  }
  &-top {
    &-10 {
      padding-top: 10px;
    }
  }
}

.width {
  &-maxContent {
    width: max-content;
  }
  &-minContent {
    width: min-content;
  }
  &-fitContent {
    width: fit-content;
  }
  &-auto {
    width: auto;
  }
  &-full {
    width: 100%;
  }
  &-134 {
    width: 134px;
  }
  &-115 {
    width: 115px;
  }
  &-190 {
    width: 190px;
  }
  &-174 {
    width: 174px;
  }
  &-320 {
    width: 320px;
  }
}

.white-space {
  &-unset {
    white-space: unset;
  }
}

.overlay {
  &-y {
    &-auto {
      overflow-y: auto;
    }
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-x {
    &-hidden {
      overflow-x: hidden;
    }
  }
}

.text-overflow {
  &-ellipse {
    text-overflow: ellipsis;
  }
}

.height {
  &-full {
    height: 100%;
  }
  &-35 {
    height: 35px;
  }
  &-40 {
    height: 40px;
  }
  &-75 {
    height: 75px;
  }
  &-1 {
    height: 1px;
  }
}

.cursor {
  &-not-allowed {
    cursor: not-allowed;
  }
  &-pointer {
    cursor: pointer;
  }
}

.min {
  &-width {
    &-fitContent {
      min-width: fit-content;
    }
  }
  &-height {
    &-70 {
      min-height: 70px;
    }
    &-full {
      height: 100%;
    }
  }
}

.max {
  &-width {
    &-134 {
      max-width: 134px;
    }
    &-397 {
      max-width: 397px;
    }
    &-450 {
      max-width: 450px;
    }
    &-500 {
      max-width: 500px;
    }
    &-600 {
      max-width: 600px;
    }
  }
}

.line-height {
  &-1-86 {
    line-height: 1.86;
  }
}

.icon {
  &-size {
    &-22 {
      font-size: 22px;
    }
  }
}

.font-size {
  &-13 {
    font-size: 13px;
  }
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
  &-16 {
    font-size: 16px;
  }
  &-18 {
    font-size: 18px;
  }
  &-15 {
    font-size: 15px;
  }
  &-20 {
    font-size: 20px;
  }
  &-22 {
    font-size: 22px;
  }
  &-25 {
    font-size: 25px;
  }
  &-26 {
    font-size: 26px;
  }
}

.font-weight {
  &-700 {
    font-weight: 700;
  }
  &-600 {
    font-weight: 600;
  }
  &-500 {
    font-weight: 500;
  }
  &-300 {
    font-weight: 300;
  }
  &-bold {
    font-weight: bold;
  }
}

.bgcolor {
  &-f5f5f5 {
    background-color: #f5f5f5;
  }
  &-fff {
    background-color: #fff;
  }
  &-hover {
    &-eee {
      &:hover {
        background-color: #eee;
      }
    }
  }
}

.color {
  &-grayTitle {
    color: #404d66;
  }
  &-4b606d {
    color: #4b606d;
  }
  &-5e646f {
    color: #5e646f;
  }
  &-7786a1 {
    color: #7786a1;
  }
  &-465565 {
    color: #465565;
  }
  &-5a6a7b {
    color: #5a6a7b;
  }
  &-657686 {
    color: #657686;
  }
  &-red {
    color: #e9455e;
  }
  &-blue {
    color: #1890ff;
  }
  &-hover {
    &-white {
      &:hover {
        color: #fff;
      }
    }
  }
}

.flex {
  display: flex;

  &-wrap {
    &-wrap {
      flex-wrap: wrap;
    }
  }
  &-1 {
    flex: 1;
  }
  &-row {
    flex-direction: row;
  }
  &-col {
    flex-direction: column;
  }
  &-align {
    &-center {
      align-items: center;
    }
  }
  &-justify {
    &-center {
      justify-content: center;
    }
    &-end {
      justify-content: end;
    }
    &-start {
      justify-content: start;
    }
    &-space-around {
      justify-content: space-around;
    }
    &-space-between {
      justify-content: space-between;
    }
  }
  &-gap {
    &-30 {
      gap: 30px;
    }
    &-25 {
      gap: 25px;
    }
    &-20 {
      gap: 20px;
    }
    &-15 {
      gap: 15px;
    }
    &-10 {
      gap: 10px;
    }
    &-5 {
      gap: 5px;
    }
  }
  &-flow {
    &-wrap {
      flex-flow: wrap;
    }
  }
  &-align-self {
    &-end {
      align-self: flex-end;
    }
  }
  &-none {
    flex: none;
  }
}

.text {
  &-align {
    &-left {
      text-align: left;
    }
  }
}

.util {
  &-select {
    &-with-arrow {
      &.ant-select-focused {
        &:before {
          transform: rotate(180deg);
        }
      }
      &:before {
        transition: transform 0.3s;
        position: absolute;
        right: 11px;
        top: 12px;
        content: ' ';
        background: url('../imgs/anticon-down.svg');
        width: 11px;
        height: 12px;
        color: gray;
        background-size: 11px 12px;
      }
    }
    &-height-35 {
      .ant-select-selection {
        height: 35px !important;
      }
    }
  }

  &-js-textarea {
    overflow-y: auto;
    font-family: 'Fira Mono';
    font-size: 12px !important;
    font-weight: 400;
    color: #ffffff;
    background-color: #667787;
  }

  &-readOnly {
    &-input {
      background-color: #f5f5f5;
      input {
        background-color: transparent;
      }
    }
  }
  &-input {
    &-blueText {
      input {
        color: #1890ff;
      }
    }
  }
  &-blue-button-active {
    border-color: #1890ff;
    background: #1890ff;
    color: #fff !important;
  }
}
