.flux-table {
  background: #fff;
}

.flux-icon {
  &-tableQuickstats,
  &-tableEdit,
  &-tableArchive,
  &-tableUnArchive,
  &-tableDelete,
  &-tableCopy,
  &-tableOpenBuilder {
    display: inline-flex;
    font-size: 16px;
    cursor: pointer;
    color: #8d9aa8;
  }
  &-tableOpenBuilder:hover svg {
    #funnel_editor {
      fill: #ffffff;
    }
  }
  &-tableQuickstats svg {
    height: 32px;
    width: 32px;
    opacity:0.8;
    &:hover{
      opacity:1.0;
      filter: brightness(10);
    }
  }
  &-tableEdit svg {
    height: 16px;
    width: 16px;
  }
  &-tableArchive svg {
    height: 16px;
    width: 21px;
  }
}

.container {
  padding: 0 15px;
  height: 100%;
  overflow: hidden;
}

.ag-theme-balham {
  .partial-height {
    position: relative;

    .ag-floating-bottom {
      height: 39px;
      width: 100%;

      .ag-floating-bottom-container {
        .ag-row {
          font-weight: bold;
          background-color: #f5f8fa;
          height: 36px !important;
        }

        [col-id='ag-Grid-AutoColumn'] {
          border-right: 0;
        }

        *:before {
          display: none;
        }
      }
    }
  }
}

.autoHeight {
  &-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-table-container {
    height: 100%;
    min-height: 240px;
    position: relative;

    .ag-theme-balham {
      height: 100%;
      min-height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      width: calc(100% - 40px);

      .ag-theme-balham {
        display: block;
        position: initial;
        min-height: 1px;
        height: 1px;
      }

      .partial-height {
        position: absolute;
        top: 0;
        height: calc(100% - 50px) !important;
        width: 100%;
      }
    }
  }
}

.table-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
