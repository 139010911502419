@import 'vars/forms';

form {
  font-family: 'Open Sans', sans-serif;

  button {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
  }

  &.flux-base-form {
    width: $base-form-width;
    padding: 12px;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 12px;
    .flux-input-group {
      margin-bottom: 12px;
    }
  }
}
