/* LAYOUT_SIZES */
$layout-height: 100vh;
$layout-height-with-ended-subscription: calc(100vh - 38px);
$layout-body-bg: #f0f2f5;
$layout-nav-img-p: 15px; //padding

$layout-nav-item-w: 100%; // width
$layout-nav-item-h: 72px; //height
$layout-item-border: 1px solid #e1e6eb;

$layout-header-body-w: 100%;
$layout-header-img-w: 35px;
$layout-header-img-h: 35px;
$layout-header-border: 1px solid #e1e6eb;

/* LAYOUT_TYPOGRAPHY */
$layout-nav-fs: 20px; //font-size
$layout-nav-fc: #333; // font-color
$layout-nav-fc-a: #1890ff; // #1890ff; // active
